import digitalData from 'digital-data';
import { STASH } from '../constants';

const pushEvent = (event) => {
  digitalData.event = digitalData.event || [];
  digitalData.event.push(event);
};

const reportOtpMethodSelectionLoaded = () => {
  const DDOEvent = {
    category: {
      primaryCategory: 'services',
      subCategory1: 'services>sign in otp',
      subCategory2: 'services>sign in otp',
    },
    pageInfo: {
      pageName: 'services>sign in otp',
      pageType: 'services: sign in',
    }
  };
  pushEvent(DDOEvent);
};

const reportOtpVerificationLoaded = () => {
  const DDOEvent = {
    category: {
      primaryCategory: 'services',
      subCategory1: 'services>sign in otp>enter code',
      subCategory2: 'services>sign in otp>enter code',
    },
    pageInfo: {
      pageName: 'services>sign in otp>enter code',
      pageType: 'services: sign in',
    }
  };
  pushEvent(DDOEvent);
};

const reportSignInSuccess = () => {
  const DDOEvent = {
    category: {
      primaryCategory: STASH,
    },
    eventInfo: {
      eventName: 'sign in success',
    },
    eventMode: STASH
  };
  pushEvent(DDOEvent);
};

export const otp = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('otp.otp-method-selection-loaded', reportOtpMethodSelectionLoaded);
  window.LIFE_CYCLE_EVENT_BUS.on('otp.otp-verification-loaded', reportOtpVerificationLoaded);
  window.LIFE_CYCLE_EVENT_BUS.on('otp.sign-in-success', reportSignInSuccess);
};
