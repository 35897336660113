import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CustomerContext } from './CustomerContext';

export const CustomerProvider = ({ children }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const persistedCustomerInformationContext = {
    isMounted,
    membershipInformation: null,
    THDCustomer: null,
    isExchangeCustomer: false,
    exchangeCustomerCookies: null,
  };

  /**
   * On page load, we attempt to get the values from local storage first and set context.
   *
   * If we have them, we can pass them down to the consumer (the useMembershipInformation hook).
   *
   * If we don't have them, we'll pass down the default null values and let the consumer...
   *
   * TODO: have consuming child update membershipInformation when call to Loyalty is made^
   */
  if (isMounted) {
    try {
      const THDCustomer = window.top.THDCustomer || window.THDCustomer;

      persistedCustomerInformationContext.membershipInformation = THDCustomer?.default?.membershipInformation;
      persistedCustomerInformationContext.THDCustomer = THDCustomer?.default;
      persistedCustomerInformationContext.isExchangeCustomer = THDCustomer?.default?.isExchangeCustomer;
      persistedCustomerInformationContext.exchangeCustomerCookies = THDCustomer?.default?.exchangeCustomerCookies;
      persistedCustomerInformationContext.isStorefrontCustomer = THDCustomer?.default?.isStorefrontCustomer;
    } catch (error) {
      // Fallback to using window.THDCustomer in case accessing window.top fails
      const THDCustomer = window.THDCustomer;

      persistedCustomerInformationContext.membershipInformation = THDCustomer?.default?.membershipInformation;
      persistedCustomerInformationContext.THDCustomer = THDCustomer?.default;
      persistedCustomerInformationContext.isExchangeCustomer = THDCustomer?.default?.isExchangeCustomer;
      persistedCustomerInformationContext.exchangeCustomerCookies = THDCustomer?.default?.exchangeCustomerCookies;
      persistedCustomerInformationContext.isStorefrontCustomer = THDCustomer?.default?.isStorefrontCustomer;
    }
  }

  return (
    <CustomerContext.Provider value={persistedCustomerInformationContext}>
      {children}
    </CustomerContext.Provider>
  );
};

CustomerProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

CustomerProvider.defaultProps = {
  children: null,
};
