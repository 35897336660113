import React from 'react';

const defaultCustomerContextValue = {
  membershipInformation: null,
  THDCustomer: null,
  isExchangeCustomer: false,
  exchangeCustomerCookies: null,
  isStorefrontCustomer: false
};

export const CustomerContext = React.createContext(defaultCustomerContextValue);
