import digitalData from 'digital-data';
import { ERROR } from '../constants';

export const creditCard = () => {
  const trackEvent = (event) => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push(event);
  };

  const actions = {
    postPageErrors: ({ output }) => {
      const {
        type, displayType, pageSection, message, code = 'n/a'
      } = output;
      const DDOEvent = {
        category: {
          primaryCategory: ERROR,
        },
        eventInfo: {
          eventName: 'post page error',
          error: [
            {
              type,
              displayType,
              pageSection,
              message,
              code,
            },
          ],
        },
      };
      trackEvent(DDOEvent);
    },

    hdWalletButtonClick: ({ output }) => {
      const { actionType, paymentMethod } = output;
      const DDOEvent = {
        category: {
          primaryCategory: 'button submission'
        },
        eventInfo: {
          eventName: 'my account settings'
        },
        myAccount: {
          settings: `cards and accounts: hd pass: add new card: ${actionType} button: ${paymentMethod}`
        },
      };
      trackEvent(DDOEvent);
    },

    handleCardToHDWalletClick: ({ output }) => {
      const { actionType, paymentMethod = '' } = output;
      const DDOEvent = {
        category: {
          primaryCategory: 'button submission'
        },
        eventInfo: {
          eventName: 'my account settings'
        },
        myAccount: {
          settings: `cards and accounts: hd pass: existing card: ${actionType} button${paymentMethod && `: ${paymentMethod}`}`
        },
      };
      trackEvent(DDOEvent);
    },

    hdPassDropdownOptionClick: ({ output }) => {
      const { actionType } = output;
      const DDOEvent = {
        category: {
          primaryCategory: 'stash'
        },
        eventInfo: {
          eventName: 'my account settings'
        },
        eventMode: 'stash',
        myAccount: {
          settings: `cards and accounts: credit cards: meatball: ${actionType}`
        },
      };
      trackEvent(DDOEvent);
    },
  };

  window.LIFE_CYCLE_EVENT_BUS.on('credit-card.actions.post-page-error', actions.postPageErrors);
  window.LIFE_CYCLE_EVENT_BUS.on(
    'credit-card.actions.hdwallet-button-click',
    actions.hdWalletButtonClick
  );
  window.LIFE_CYCLE_EVENT_BUS.on(
    'credit-card.actions.to-hdwallet-button-click',
    actions.handleCardToHDWalletClick
  );
  window.LIFE_CYCLE_EVENT_BUS.on(
    'credit-card.actions.hdpass-dropdown-click',
    actions.hdPassDropdownOptionClick
  );

};
