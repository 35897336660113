import digitalData from 'digital-data';
import {
  APPL,
  ATC,
  ADD_TO_CART_CLICK,
  CART_ADD,
  CART_ADD_LOCATION,
  HDPP_CART_ADD,
  OVERLAY,
  PARTS_AND_SERVICES,
  PARTS_AND_SERVICES_DRAWER,
  NOT_APPLICABLE,
  STASH
} from '../constants';

export const partsAndServicesDrawer = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('parts-and-services-drawer.open', () => {

    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: PARTS_AND_SERVICES_DRAWER
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('parts-and-services-drawer.atc-hdpp', ({ output }) => {

    const psOverlays = [];
    const items = [];

    output.forEach((ele) => {
      let protection = {
        anchorSku: ele.info.item.product.itemId,
        hdppOption: ele.hasProtection ? 'y' : 'n',
        partsOption: ele.hasParts ? 'y' : 'n',
      };

      let itemInfo = {
        fulfillment: {
          method: APPL,
          zip: ele.info.item.fulfillmentLocation
        },
        price: {
          basePrice: ele.info.item.product.pricing.original
        },
        productInfo: {
          sku: ele.info.item.product.itemId
        },
        quantity: '1'
      };
      psOverlays.push(protection);
      items.push(itemInfo);
    });

    const ddoEvent1 = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: CART_ADD_LOCATION
      },
      eventmode: STASH,
      stash: {
        component: NOT_APPLICABLE,
        pageType: PARTS_AND_SERVICES,
        section: NOT_APPLICABLE,
        sharedSection: HDPP_CART_ADD,
        target: ATC
      }
    };

    const ddoEvent2 = {
      category: {
        primaryCategory: ADD_TO_CART_CLICK
      },
      eventInfo: {
        eventName: CART_ADD
      },
      item: items,
      attributes: {
        psOverlay: psOverlays
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent1);
    digitalData.event.push(ddoEvent2);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('parts-and-services-drawer.atc-installation-haulaway', ({ output }) => {

    const items = [];
    const psOverlays = [];

    output.forEach((ele) => {

      let itemInfo = {
        fulfillment: {
          method: APPL,
          zip: ele.info.fulfillmentLocation
        },
        price: {
          basePrice: ele.info.item.product.pricing.original
        },
        productInfo: {
          sku: ele.info.item.product.itemId
        },
        quantity: '1'
      };

      let psOverlay = {
        anchorSku: ele.info.item.product.itemId,
        installOption: ele.hasInstallation ? 'y' : 'n',
        partsOption: ele.hasParts ? 'y' : 'n',
        haulOption: ele.haulAwayOption
      };

      items.push(itemInfo);
      psOverlays.push(psOverlay);
    });

    const ddoEvent1 = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: CART_ADD_LOCATION
      },
      eventmode: STASH,
      stash: {
        component: NOT_APPLICABLE,
        pageType: PARTS_AND_SERVICES,
        section: NOT_APPLICABLE,
        sharedSection: HDPP_CART_ADD,
        target: ATC
      }
    };

    const ddoEvent2 = {
      category: {
        primaryCategory: ADD_TO_CART_CLICK
      },
      eventInfo: {
        eventName: CART_ADD
      },
      item: items,
      attributes: {
        psOverlay: psOverlays
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent1);
    digitalData.event.push(ddoEvent2);
  });

};