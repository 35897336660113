import THDCustomer from '@thd-olt-global/thd-customer';

let readyIsCalled = false;

function isAuthenticated() {
  try {
    const loggedIn = THDCustomer?.isLoggedIn;
    const isKMSI = THDCustomer?.isKMSI;
    const isPersistedKMSI = THDCustomer?.isPersistedKMSI;
    if (loggedIn) return true;
    if (isKMSI && isPersistedKMSI) return true;
    return false;
  } catch (err) {
    console.log(err);
  }

  return null;
}

export const customVariables = () => {
  if (typeof window !== 'undefined') {
    const { version, name } = window.experienceMetadata || {};
    if (window.newrelic && name && version) {
      window.newrelic.setCustomAttribute('expVersion', version);
      window.newrelic.setCustomAttribute('expName', name);

      window.newrelic.addRelease(name, version);

      if (typeof window.__RENDER_DATE !== 'undefined') {
        const ts = window.__RENDER_DATE;
        const now = new Date().getTime();
        const diff = now - ts;
        window.newrelic.setCustomAttribute('renderAge', diff);
      }
      setTimeout(() => {
        if (typeof window.ttMETA !== 'undefined' && window.ttMETA.length) {
          const tntId = window.ttMETA.map((campaign) => {
            return campaign.CampaignId;
          }).join(',');
          const tntName = window.ttMETA.map((campaign) => {
            return `[${campaign.CampaignId}:${campaign.CampaignName}]`;
          }).join(', ');

          window.newrelic.setCustomAttribute('tntId', tntId);
          window.newrelic.setCustomAttribute('tntName', tntName);
        }
      }, 4000);
    }
  }

  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('experience-context.ready', ({ output }) => {
    if (readyIsCalled) return;

    if (typeof window === 'undefined' || !window.newrelic) return;

    readyIsCalled = true;

    const {
      store, clientStore, segment, customer, channel, isConsumerApp, experiment
    } = output || {};

    const cookieUtils = window.cookieUtils || window.__cookieUtils;
    if (typeof cookieUtils !== 'undefined') {
      cookieUtils.initializeCookieManager();
      const bmanCookie = cookieUtils.readCookie('_bman');
      if (bmanCookie) {
        window.newrelic.setCustomAttribute('_bman', bmanCookie);
      }
    }

    const { featureVersion, requestedExperience } = window.experienceMetadata || {};
    if (featureVersion) {
      window.newrelic.setCustomAttribute('expFeatureVersion', featureVersion);
    }
    if (requestedExperience) {
      window.newrelic.setCustomAttribute('requestedExperience', requestedExperience);
    }

    if (segment) {
      window.newrelic.setCustomAttribute('segment', segment);
    }

    const storeId = clientStore?.storeId || store?.storeId;
    if (storeId) {
      window.newrelic.setCustomAttribute('storeId', storeId);
    }

    const { type: customerType, localizationStatus } = customer || {};
    if (customerType) {
      window.newrelic.setCustomAttribute('customerType', customerType);
    }
    if (localizationStatus) {
      window.newrelic.setCustomAttribute('localizationStatus', localizationStatus);
    }

    if (channel) {
      window.newrelic.setCustomAttribute('channel', channel);
    }

    const isAuth = isAuthenticated();
    if (typeof isAuth !== 'undefined') {
      window.newrelic.setCustomAttribute('isLoggedIn', isAuth);
    }

    if (typeof isConsumerApp !== 'undefined') {
      window.newrelic.setCustomAttribute('isConsumerApp', isConsumerApp);
    }

    if (experiment) {
      const campaignGroups = Object.keys(experiment);
      if (campaignGroups.length > 0) {
        const hasCampaigns = campaignGroups.some((key) => {
          return Object.keys(experiment[key]).length > 0;
        });

        if (hasCampaigns) {
          window.newrelic.setCustomAttribute('edgeWorkerCampaignInfo', JSON.stringify(experiment));
        }
      }
    }

    const isExchange = THDCustomer?.isExchangeCustomer;
    if (typeof isExchange !== 'undefined') {
      window.newrelic.setCustomAttribute('isExchange', isExchange);
    }

    const exchangeType = THDCustomer?.buyerOrg;
    if (typeof exchangeType !== 'undefined') {
      window.newrelic.setCustomAttribute('exchangeType', exchangeType);
    }
  });
};
