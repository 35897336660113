let prerenderState = false;

const publishPrerenderState = () => {
  if (typeof window !== 'undefined' && window.newrelic) {
    window.newrelic.setCustomAttribute('prerendered-load', prerenderState);
  }
};

export const prerenderObserver = () => {
  if (typeof document !== 'undefined') {
    if (document.prerendering) {
      prerenderState = true;
      document.addEventListener('prerenderingchange', publishPrerenderState, {
        once: true,
      });
    } else {
      publishPrerenderState();
    }
  }
};