import digitalData from 'digital-data';
import { setAnalytics } from '../thd-analytics-utils';

export const proHouseAccount = () => {

  const proHouseAccountEvents = {
    error: ({ output }) => {
      const {
        type, displayType, pageSection, message, code
      } = output;

      digitalData.page = {
        error: {
          type,
          displayType,
          pageSection,
          message,
          code,
        },
      };
      setAnalytics(digitalData);
    }
  };

  window.LIFE_CYCLE_EVENT_BUS.on('pro-house-account.actions.page-load-error', proHouseAccountEvents.error);
};
