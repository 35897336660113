/* eslint-disable no-param-reassign */
import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import {
  DD_PAGE_NAME, DD_SITE_NAME, DD_PAGE_EVENT, EMPTY_CART, NUCLEUS, PAGE_DEFAULT_TEMPLATE
} from '../../../constants';

let enableCheckoutAdobeAnalytics = false;
let override = null;

const setEventInfo = (eventObject, variables = {}) => {
  eventObject.eventInfo = {
    ...variables
  };
};

const publishEvent = ({ eventObject }) => {
  try {
    if (!enableCheckoutAdobeAnalytics) {
      console.warn('Checkout Adobe Analytics (Event) is disabled', eventObject);
      return;
    }
    // if override function exists
    if (override && typeof override === 'function') {
      override(eventObject);
    }
    digitalData.event.push(eventObject);
  } catch (error) {
    console.error('Error in publishEvent()', error);
  }
};

const handlePoJobNameEvent = ({ poJobEvent, poJobClient }) => {
  try {
    const eventObject = {
      category: {
        primaryCategory: 'button submission'
      },
      myAccount: {
        settings: `${poJobClient}: po job name: ${poJobEvent}`
      }
    };
    setEventInfo(eventObject, {
      eventName: 'my account settings'
    });
    publishEvent({ eventObject });
  } catch (error) {
    console.error(error);
  }
};

export function myCart() {
  window.LIFE_CYCLE_EVENT_BUS.on('myCart.page-actions', ({ output = null }) => {
    try {
      const analyticsEventName = output?.analyticsEventName || '';
      enableCheckoutAdobeAnalytics = output?.enableCheckoutAdobeAnalytics || false;
      override = output?.override || null;

      switch (analyticsEventName) {
      case 'poJobSuccess': // handles add and edit
      case 'poJobRemoved':
        handlePoJobNameEvent(output?.actionObject);
        break;
      default:
        console.warn('Unable to find checkout analytics event of \'' + analyticsEventName + '\'');
        break;
      }
    } catch (error) {
      console.error('Error in checkout analytics', error);
    }
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('myCart.page-load', ({ output = null }) => {
    let url;
    let logout;
    let categoryArray;
    let subCategory2;
    let page;
    const isEmptyCart = output?.isEmptyCart || false;
    page = isEmptyCart ? EMPTY_CART : DD_PAGE_NAME;
    let siteName = DD_SITE_NAME;
    let pageEvent = isEmptyCart ? EMPTY_CART : DD_PAGE_EVENT;
    url = window.location.href;
    logout = url.indexOf('/Logout');
    if (logout !== -1) {
      page = 'Logout';
    }
    categoryArray = page.split('>');
    subCategory2 = '';

    if (categoryArray.length > 1) {
      subCategory2 = '>' + categoryArray[1];
    }

    if (output?.cart) {
      digitalData.cart = output.cart;
    }

    if (output?.content) {
      digitalData.content = output.content;
    }

    digitalData.page = {
      pageInfo: {
        pageName: siteName + '>' + page,
        pageType: siteName,
        abTest: 'mycart v1',
        pageLoadEvent: (pageEvent !== '' || page === 'quick add' ? pageEvent : page),
        pageTemplate: PAGE_DEFAULT_TEMPLATE,
        platform: NUCLEUS
      },
      category: {
        primaryCategory: siteName,
        subCategory1: siteName + '>' + categoryArray[0],
        subCategory2: siteName + '>' + categoryArray[0] + subCategory2
      },
      error: output?.pageErrors || []
    };

    if (typeof output?.validate === 'function') {
      digitalData.page = output.validate(digitalData.page) || digitalData.page;
    }

    setAnalytics(digitalData);
  });
}