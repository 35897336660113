import { getStoreAndShippingAvailabilityStatus, transformFulfillmentToOldDS } from '../../../thd-analytics-utils';

export const getProductInfo = (products) => {
  const productInfo = products?.map((product) => {
    const fulfillment = transformFulfillmentToOldDS(product?.fulfillment || {});
    return {
      productInfo: {
        availabilityStatus: {
          backOrder: product?.fulfillment?.backordered ? 'yes' : 'no',
          ...getStoreAndShippingAvailabilityStatus(product?.fulfillment, product?.availabilityType)
        },
        manufacturer: product?.identifiers?.brandName,
        productName: product?.identifiers?.productLabel,
        productType: product?.identifiers?.productType,
        sku: product?.itemId,
        installationDisplayed: false,
        proreferralDisplayed: false,
        superDuperSkuInteraction: false,
        storeType: fulfillment.storeType || 'not localized',
        eta: getETA(fulfillment),
        collectionInteraction: false
      }
    };
  });
  return productInfo;
};

const getETA = (fulfillment) => {
  let eta = {};

  if (fulfillment?.isApplianceDelivery && fulfillment?.applianceDelivery?.deliveryDates?.startDate) {
    eta.applStartDate = fulfillment?.applianceDelivery?.deliveryDates?.startDate;
    eta.applEndDate = fulfillment?.applianceDelivery?.deliveryDates?.endDate || eta.applStartDate || '';
  }
  if (fulfillment?.isBODFS && fulfillment?.bodfs?.deliveryDates?.startDate) {
    eta.bodfsStartDate = fulfillment?.bodfs?.deliveryDates?.startDate;
    eta.bodfsEndDate = fulfillment?.bodfs?.deliveryDates?.endDate || eta.bodfsStartDate || '';
  }
  if (fulfillment?.isBOSS && fulfillment?.boss?.deliveryDates?.startDate) {
    eta.bossStartDate = fulfillment?.boss?.deliveryDates?.startDate;
    eta.bossEndDate = fulfillment?.boss?.deliveryDates?.endDate || eta.bossStartDate || '';
  }
  if (fulfillment?.isSTH && fulfillment?.shipToHome?.deliveryDates?.startDate) {
    eta.sthStartDate = fulfillment?.shipToHome?.deliveryDates?.startDate;
    eta.sthEndDate = fulfillment?.shipToHome?.deliveryDates?.endDate || eta.sthStartDate;
  }
  return eta;
};

export const getBundleType = (product) => {
  const { type, experienceType } = product?.bundleSpecificationDetails ?? {};
  const bundleType = (!!experienceType && !!type) ? type?.concat('>', experienceType) : type;
  return bundleType;
};