import digitalData from 'digital-data';
import {
  STASH, NOT_APPLICABLE, RECOMMENDATION_EVENTNAME, CART_ADD_RECS, PRODUCT_POD, ATC
} from '../constants';
import { getRecommendationType, getBipCartItems } from '../thd-analytics-utils';
import { getState } from '../analytics-state';

export const bundleMini = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('bundle-mini.viewBundleClick', ({ output }) => {
    const { itemId, product } = output ?? {};
    const { pageType } = getState() ?? {};
    const analyticsEvent = {
      category: {
        primaryCategory: STASH,
      },
      eventInfo: {
        eventName: RECOMMENDATION_EVENTNAME,
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          pageType,
          recommendationType: getRecommendationType(product),
          displayPosition: NOT_APPLICABLE,
          anchorProductSku: itemId,
          strategy: NOT_APPLICABLE,
          recommendationVersion: NOT_APPLICABLE,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE,
        },
      },
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(analyticsEvent);
  });
  window.LIFE_CYCLE_EVENT_BUS.on('bundle-mini.bipAddToCart', ({ output }) => {
    const { items = [], product } = output;
    const { pageType } = getState() ?? {};
    const ddoEvent = {
      eventInfo: {
        eventName: CART_ADD_RECS
      },
      category: {
        primaryCategory: STASH
      },
      eventMode: STASH,
      stash: {
        component: PRODUCT_POD,
        item: getBipCartItems(items, product),
        pageType,
        section: 'dyn prod rec',
        sharedSection: NOT_APPLICABLE,
        target: ATC
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};