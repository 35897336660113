import digitalData from 'digital-data';
import { getState, setState } from '../analytics-state';
import {
  BROWSE, BUTTON_SUBMISSION, COLLECTION, CTL_STRATEGY, CUSTOM_CATALOG, HOMEPAGE_SPONSORED,
  CART_ADD_LOCATION, COLLECTION_PAGETYPE_LIST_VIEW, COLLECTION_PAGETYPE_INTEGRATED_BRANDS,
  NOT_APPLICABLE, NOT_CUSTOM_CATALOG, PLP, PRODUCT_POD, RECENTLY_VIEWED_EVENTNAME,
  RECOMMENDATION_EVENTNAME, RECOMMENDATION_VERSION, RECS_CART_ADDITION_INFO, CART_ADDITION_INFO,
  COLLECTION_PAGETYPE_SPACE, SEARCH, SEARCH_RESULTS, SP, SPONSORED_RECOMMENDATION_PIP_LOAD, STASH,
  STRATEGY, MY_LIST_DETAILS_PAGE_TYPE, TABLEVIEW, MY_HOMEPAGE, SECTION, ATC,
  CTL_RECOMMENDATION_VERSION, SPONSORED_PROD_REC, IRG, RECENTLY_VIEWED, SPONSORED_CAROUSEL,
  RECS_PRODUCT_POD_CAROUSEL, INSTANT_RECS, PLP_RECS_PLACEHOLDER, SUPPLEMENTAL_RECS, FBT_CAROUSEL,
  APL_BUNDLE, VISUAL_RECOMMENDATION, SBR_COLLECTION, SPECIAL_BUY_BANNER_CAROUSEL,
  CURATION_CAROUSEL, MORE_IN_THIS_COLLECTION_SECTION, PROJECT_SHOPPING, DISCOVERY_ZONES,
  DISCOVERY_ZONES_BUYITAGAIN, FEATURE_BASED_RECOMMENDATIONS, RECS_DTS_PRODUCT_POD_CAROUSEL,
  MY_ACCOUNT_SETTINGS, IBT_BATH_COLLECTION
} from '../constants';
import { getCategoryBreadCrumbs } from '../thd-analytics-utils';
import { getCollectionPageType } from '../experience/pages/collections/collections';

export const productPodComposable = () => {

  const getProductData = (itemId, parent) => {
    const { productpods = [] } = getState();
    const key = `${itemId}-${parent}`;
    return productpods.find((item) => item.key === key);
  };

  const updatePodInteractionCount = (itemId, parent) => {
    const { productpods = [] } = getState();
    const key = `${itemId}-${parent}`;
    if (itemId) {
      setState({
        productpods: productpods.map((item) => {
          if (item.key === key) {
            return { ...item, podInteractionNumber: item.podInteractionNumber + 1 };
          }
          return item;
        })
      });
    }
  };

  const visualRecommendationAnalytics = (productData = {}) => {
    const { position } = productData;
    const { pageType } = digitalData?.page?.pageInfo;
    const visualRecEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: 'recommendation pip load'
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          recommendationType: 'vis sim',
          pageType,
          displayPosition: position,
          anchorProductSku: NOT_APPLICABLE,
          strategy: NOT_APPLICABLE,
          recommendationVersion: RECOMMENDATION_VERSION,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        }
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(visualRecEvent);
  };

  const recentlyViewedAnalytics = (productData) => {
    const { position, scheme = 'rv' } = productData || {};
    const { pageItemId = '' } = getState() || {};
    const pageType = digitalData?.page?.pageInfo.pageType;
    const recentlyViewedEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: RECENTLY_VIEWED_EVENTNAME
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          recommendationType: scheme.split('-')[0],
          pageType,
          displayPosition: position,
          anchorProductSku: pageItemId || NOT_APPLICABLE,
          featureVersion: NOT_APPLICABLE,
          strategy: STRATEGY,
          recommendationVersion: RECOMMENDATION_VERSION,
          recommendedProduct: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        }
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(recentlyViewedEvent);
  };

  const sponsoredCarouselAnalyticsClick = (productData = {}) => {
    const pageType = digitalData?.page?.pageInfo.pageType;
    const { position } = productData;
    const { pageItemId = '' } = getState() || {};
    const sponsoredCarouselClickEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: SPONSORED_RECOMMENDATION_PIP_LOAD
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          recommendationType: SP,
          pageType,
          displayPosition: position,
          anchorProductSku: pageItemId,
          strategy: NOT_APPLICABLE,
          recommendationVersion: NOT_APPLICABLE,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        }
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(sponsoredCarouselClickEvent);
  };

  const recommendationAnalytics = (productData) => {
    const { pageType = '', pageItemId = '' } = getState() || {};
    const currentPageType = digitalData?.page?.pageInfo?.pageType || '';
    const { scheme = '', position, anchorProduct = '', strategy = '' } = productData || {};
    const pageTypeValue = (typeof pageType === 'undefined' || pageType === 'unknown' || !pageType)
      ? currentPageType : pageType;
    const isSponsored = productData?.sponsoredValues?.isSponsored;
    const recommendationEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: RECOMMENDATION_EVENTNAME
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          recommendationType: scheme,
          pageType: pageTypeValue,
          displayPosition: position,
          anchorProductSku: anchorProduct || pageItemId,
          featureVersion: NOT_APPLICABLE,
          strategy: strategy || STRATEGY,
          recommendationVersion: RECOMMENDATION_VERSION,
          recommendedProduct: NOT_APPLICABLE,
          interval: NOT_APPLICABLE,
          sponsoredProduct: isSponsored ? SP : NOT_APPLICABLE
        }
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(recommendationEvent);
  };

  const fbtRecommendationAnalytics = (productData = {}) => {
    const { position, strategy = '', scheme = '' } = productData || {};
    const { pageItemId = '', pageType = '' } = getState() || {};
    const fbtRecommendationEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: RECOMMENDATION_EVENTNAME
      },
      eventMode: STASH,
      isReported: true,
      stash: {
        recommendation: {
          recommendationType: scheme || 'fbt',
          pageType,
          displayPosition: position,
          anchorProductSku: pageItemId,
          featureVersion: NOT_APPLICABLE,
          strategy: strategy || NOT_APPLICABLE,
          recommendationVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        }
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(fbtRecommendationEvent);
  };

  const aplBundleRecommendationAnalytics = (productData = {}) => {
    const { scheme = '' } = productData || {};
    const { pageItemId = '', pageType = '' } = getState() || {};
    const aplBundleRecommendationEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: RECOMMENDATION_EVENTNAME
      },
      eventMode: STASH,
      isReported: true,
      stash: {
        recommendation: {
          recommendationType: scheme || 'product bundle',
          pageType,
          displayPosition: NOT_APPLICABLE,
          anchorProductSku: pageItemId,
          featureVersion: NOT_APPLICABLE,
          strategy: NOT_APPLICABLE,
          recommendationVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        }
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(aplBundleRecommendationEvent);
  };

  const collectionsAnalytics = (podAnchorSku = '', parent) => {
    const { dataSources } = getState();
    const breadcrumbs = dataSources?.collection?.taxonomy?.breadCrumbs;
    const { primaryCategory } = getCategoryBreadCrumbs(breadcrumbs);
    const collectionName = dataSources?.collection?.metadata?.name?.toLowerCase();
    const collectionEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: 'collections product click'
      },
      eventMode: STASH,
      stash: {
        product: {
          productInfo: {
            sku: podAnchorSku
          }
        },
        collection: {
          pageLocation: parent?.toLowerCase() === IBT_BATH_COLLECTION
            ? `${primaryCategory.toLowerCase()}>${collectionName}` : ''
        }
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(collectionEvent);
  };
  const sponsoredTracking = (sponsoredClickBeacons) => {
    const validBeacons = sponsoredClickBeacons.filter(Boolean);
    const showEvents = typeof window !== 'undefined' && window.location.search.indexOf('showEvents=true') !== -1;

    if (validBeacons.length === 0) {
      if (showEvents) {
        console.warn('No valid tracking links provided');
      }
      return;
    }
    validBeacons.forEach((trackingLink) => {
      fetch(trackingLink, {
        method: 'GET',
        keepalive: true,
      })
        .catch((error) => {
          if (showEvents) {
            console.error(`Error occurred while triggering event for:${trackingLink}`, error);
          }
        });
    });
  };

  const getSharedRecsAnalytics = (productData = {}) => {
    const pageType = digitalData?.page?.pageInfo.pageType;
    return {
      category: {
        primaryCategory: STASH
      },
      eventMode: STASH,

      stash: {
        recommendation: {
          recommendationType: 'sp',
          pageType,
          displayPosition: productData?.position,
          anchorProductSku: NOT_APPLICABLE,
          strategy: NOT_APPLICABLE,
          recommendationVersion: 'HDPlpPodSponsored',
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE,
        },
      },
    };
  };

  const pipRecsAnalytics = (productData = {}) => {
    const pipAnalytics = {
      eventInfo: {
        eventName: SPONSORED_RECOMMENDATION_PIP_LOAD
      }
    };

    const recsAnalyticsEvent = Object.assign(pipAnalytics, getSharedRecsAnalytics(productData));
    digitalData.event = digitalData.event || [];
    digitalData.event.push(recsAnalyticsEvent);
  };

  const sponsoredClickThroughAnalytics = (sponsoredMetadata) => {
    const sponsoredPodClickThroughEvent = {
      category: {
        primaryCategory: 'interaction'
      },
      eventInfo: {
        eventName: 'content media click'
      },
      creativeID: null,
      placementID: sponsoredMetadata?.placementId,
      campaignID: sponsoredMetadata?.campaignId,
      slotID: sponsoredMetadata?.slotId,
      sponsoredID: sponsoredMetadata?.sponsoredId,
      trackSource: sponsoredMetadata?.trackSource,
    };

    digitalData.event.push(sponsoredPodClickThroughEvent);
  };

  const badgeClickAnaltytics = ({ badges = [] }) => {
    let { pageType = null, dataSources } = getState() || {};
    let recommendationVersion = 'model1';

    if (pageType === SEARCH) {
      pageType = SEARCH_RESULTS;
    } else if (pageType === BROWSE) {
      pageType = PLP;
    } else if (pageType === COLLECTION) {
      const collectionType = dataSources?.collection?.metadata?.type;
      if (collectionType === 'Space') {
        pageType = `collections:${getCollectionPageType({ type: collectionType })}`;
        recommendationVersion = NOT_APPLICABLE;
      }
    }

    const ddoEvent = {
      stash: {
        badge: [
          {
            type: badges[0]?.label || badges[0]?.name || NOT_APPLICABLE,
            pageType,
            recommendationStrategy: NOT_APPLICABLE,
            recommendationVersion,
          }
        ],
      },
      eventInfo: {
        eventName: 'badge clickthrough'
      },
      category: {
        primaryCategory: 'stash'
      },
      eventMode: 'stash',
      isReported: true,
      sourceIndex: 1,
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  };

  const sponsoredProductAtcClickEvent = (output = {}, productData = {}) => {
    const { podAnchorSku } = output;
    const { position } = productData;
    const productId = podAnchorSku;
    const { pageItemId = '' } = getState() || {};
    const pageType = digitalData?.page?.pageInfo?.pageType;
    const sponsoredProductAtc = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: 'sponsored recommendation cart addition'
      },
      eventMode: STASH,
      stash: {
        component: PRODUCT_POD,
        item: [{
          position,
          productId,
        }],
        pageType,
        section: SPONSORED_PROD_REC,
        sharedSection: NOT_APPLICABLE,
        target: ATC,
        recommendation: {
          recommendationType: SP,
          pageType,
          displayPosition: position,
          anchorProductSku: pageItemId,
          strategy: NOT_APPLICABLE,
          recommendationVersion: NOT_APPLICABLE,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        }
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(sponsoredProductAtc);
  };

  const projectShoppingAtcAnalytics = (output = {}) => {
    const { category } = output; // ???????
    const { pageType = '' } = getState() || {};

    const projectShoppingEvent = {
      category: {
        primaryCategory: STASH
      },

      eventInfo: {
        eventName: CART_ADD_LOCATION
      },

      eventMode: STASH,

      stash: {
        component: PRODUCT_POD,
        pageType,
        section: 'project shopping drawer',
        sharedSection: category,
        target: ATC
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(projectShoppingEvent);
  };

  const cartRecsAnalytics = (output = {}, productData = {}) => {
    const { pageType = '' } = getState() || {};
    const cartAnalytics = {
      eventInfo: {
        eventName: 'sponsored recommendation cart addition'
      },
      stash: {
        component: PRODUCT_POD,
        item: output.items,
        pageType,
        section: SPONSORED_PROD_REC,
        sharedSection: NOT_APPLICABLE,
        target: ATC,
      },
    };

    const sharedAnalytics = getSharedRecsAnalytics(productData);
    const analyticsEvent = Object.assign(cartAnalytics, sharedAnalytics);
    digitalData.event = digitalData.event || [];
    digitalData.event.push(analyticsEvent);
  };

  const discoveryZoneAnalytics = (productData) => {
    const {
      position,
      zoneName,
      zonePosition,
      podResults,
      itemId
    } = productData || {};
    const discoveryZoneEvent = {
      category: {
        primaryCategory: 'interaction'
      },
      eventInfo: {
        eventName: 'discovery zone pip load'
      },
      discoveryZone: {
        displayPosition: position,
        zoneName,
        anchorSku: itemId,
        podResults,
        zonePosition,
        strategy: 'n/a'
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(discoveryZoneEvent);
  };

  const projectsAddItemsAnalytics = () => {
    const projectsPodEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS
      },
      eventMode: STASH,
      stash: {
        myAccount: {
          settings: 'projects: add items to project overlay: click to pip'
        }
      }

    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(projectsPodEvent);
  };

  window.LIFE_CYCLE_EVENT_BUS.on('product-pod-v7.load', ({ output }) => {
    const { productpods = [] } = getState();
    const { itemId, parent = '' } = output || {};
    const ITEM_KEY = `${itemId}-${parent}`;
    if (productpods.find((obj) => obj.key === ITEM_KEY)) {
      setState({
        productpods: productpods.map((obj) => {
          if (obj.key === ITEM_KEY) return { ...output, podInteractionNumber: 1, key: ITEM_KEY };
          return obj;
        })
      });
    } else {
      setState({
        productpods: [
          ...productpods,
          { ...output, podInteractionNumber: 1, key: ITEM_KEY }
        ]
      });
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-pod-v7.click', ({ output }) => {
    const {
      podAnchorSku,
      podAction,
      target,
      parent

    } = output || {};

    const {
      pageType = null,
      pageSize: podPageResults,
      currentPage: podPageNumber,
      dataSources,
      pageItemId = ''
    } = getState();

    const podType = 'product';

    const productData = getProductData(podAnchorSku, parent);

    const {
      badges,
      sponsoredValues,
      position: podPosition,
      podInteractionNumber,
      irgSectionName
    } = productData || {};

    const {
      isSponsored,
      sponsoredBeacon,
      sponsoredMetadata
    } = sponsoredValues || {};

    const { onClickBeacons, onClickBeacon } = sponsoredBeacon || {};
    const sponsoredClickBeacons = onClickBeacons || [onClickBeacon];
    const sponsoredProduct = !!(isSponsored);

    const isATCEvent = podAction === 'Add to cart';
    const opensInNewTab = target === '_blank';
    const isNewTabEvent = opensInNewTab && !isATCEvent;
    const isCollection = pageType === COLLECTION;

    const ddoEvent = {
      category: {
        primaryCategory: isNewTabEvent ? BUTTON_SUBMISSION : STASH
      },
    };

    if (isNewTabEvent) {
      ddoEvent.eventInfo = {
        eventName: 'plp pod new tab'
      };
      ddoEvent.pod = {
        podPageNumber,
        podPosition,
        podType,
        podPageResults,
        podAction,
        podInteractionNumber,
        podAnchorSku: podAnchorSku || pageItemId,
        sponsoredProduct
      };
      if (parent === 'ProjectFormsPreviouslyPurchased') {
        projectsAddItemsAnalytics();
      }
    } else if (parent === IRG) {
      ddoEvent.eventInfo = {
        eventName: 'recommendation pip load'
      };
      ddoEvent.stash = {
        recommendation: {
          recommendationType: 'irg',
          pageType,
          displayPosition: podPosition,
          anchorProductSku: pageItemId,
          recommendedProduct: NOT_APPLICABLE,
          strategy: irgSectionName || NOT_APPLICABLE,
          recommendationVersion: NOT_APPLICABLE,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        }
      };
    } else if (parent === RECENTLY_VIEWED) {
      recentlyViewedAnalytics(productData);
    } else if (parent === SPONSORED_CAROUSEL) {
      sponsoredCarouselAnalyticsClick(productData);
    } else if ([
      RECS_PRODUCT_POD_CAROUSEL,
      INSTANT_RECS,
      PLP_RECS_PLACEHOLDER,
      SUPPLEMENTAL_RECS,
      FEATURE_BASED_RECOMMENDATIONS,
      RECS_DTS_PRODUCT_POD_CAROUSEL
    ].some((element) => parent.startsWith(element))) {
      recommendationAnalytics(productData);
    } else if (parent === FBT_CAROUSEL) {
      fbtRecommendationAnalytics(productData);
    } else if (parent === APL_BUNDLE) {
      aplBundleRecommendationAnalytics(productData);
    } else if (parent?.includes(DISCOVERY_ZONES)) {
      discoveryZoneAnalytics(productData);
    } else {
      ddoEvent.eventMode = STASH;
      ddoEvent.eventInfo = {
        eventName: 'product pod click'
      };
      const podCount = isCollection ? dataSources?.collection?.metadata?.productCount?.allProducts
        : podPageResults;
      ddoEvent.stash = {
        podPageNumber,
        podPosition,
        podType,
        podPageResults: podCount,
        podAction,
        podInteractionNumber,
        podAnchorSku: pageItemId || podAnchorSku
      };
      if (!isCollection) {
        ddoEvent.stash.sponsoredProduct = sponsoredProduct;
        ddoEvent.eventInfo = {
          eventName: 'product pod click'
        };
        ddoEvent.stash.customProduct = badges?.find(({ name } = {}) => { return name === 'cpo'; })
          ? CUSTOM_CATALOG
          : NOT_CUSTOM_CATALOG;
      }
    }
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);

    if (badges?.length) {
      badgeClickAnaltytics({ badges });
    }

    if (isCollection) {
      collectionsAnalytics(podAnchorSku, parent);
    }

    if (parent === VISUAL_RECOMMENDATION) {
      visualRecommendationAnalytics(productData);
    }

    if (sponsoredProduct && sponsoredClickBeacons.length > 0 && !isATCEvent) {
      sponsoredTracking(sponsoredClickBeacons);

    }

    if (sponsoredProduct && sponsoredMetadata) {
      sponsoredClickThroughAnalytics(sponsoredMetadata);
    }
    updatePodInteractionCount(podAnchorSku, parent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-pod-v7.add-to-cart', ({ output }) => {
    const {
      podAnchorSku,
      parent,
      section = 'gridview',
      sharedSection = PLP,
      isTableView = false
    } = output || {};

    const {
      pageType = '',
      dataSources,
      pageItemId = ''
    } = getState() || {};

    const productData = getProductData(podAnchorSku, parent);

    const {
      sponsoredValues,
      position: podPosition,
      irgSectionName,
      anchorProduct,
      scheme,
      strategy
    } = productData || {};

    const {
      isSponsored,
      sponsoredBeacon,
      sponsoredMetadata
    } = sponsoredValues || {};

    const productId = podAnchorSku;
    const items = [{ productId, index: podPosition }];

    const { onClickBeacons, onClickBeacon } = sponsoredBeacon || {};
    const sponsoredClickBeacons = onClickBeacons || [onClickBeacon];
    const sponsoredProduct = !!(isSponsored);

    const extraEventData = {};
    const currentPageType = digitalData?.page?.pageInfo?.pageType || '';
    const isCollection = pageType === COLLECTION;
    const isArticle = currentPageType === 'article';
    const isHomepage = currentPageType === MY_HOMEPAGE;
    const collectionType = dataSources?.collection?.metadata?.type;
    const collectionName = dataSources?.collection?.metadata?.name?.toLowerCase();
    const breadcrumbs = dataSources?.collection?.taxonomy?.breadCrumbs;
    const { primaryCategory } = getCategoryBreadCrumbs(breadcrumbs);

    const isSearchOrBrowse = [SEARCH, BROWSE].indexOf(pageType) > -1;

    if (parent === SPONSORED_CAROUSEL) {
      sponsoredProductAtcClickEvent(output, productData);
      if (sponsoredProduct && sponsoredClickBeacons.length > 0) {
        sponsoredTracking(sponsoredClickBeacons);

      }
      sponsoredClickThroughAnalytics(sponsoredMetadata);
      return;
    }

    if (parent === PROJECT_SHOPPING) {
      projectShoppingAtcAnalytics(output);
      return;
    }

    let stash = {
      component: PRODUCT_POD,
      item: items,
      pageType,
      section,
      sharedSection,
      target: ATC
    };

    let eventInfo = {
      eventName: CART_ADD_LOCATION
    };

    if (isArticle) {
      stash.pageType = 'article';
      stash.section = 'related products';
      stash.sharedSection = SEARCH_RESULTS;
    } else if (pageType === SEARCH) {
      stash.pageType = SEARCH_RESULTS;
    } else if (pageType === BROWSE) {
      stash.pageType = PLP;
    }

    if (parent === PLP) {
      stash.pageType = isSearchOrBrowse ? stash.pageType : COLLECTION_PAGETYPE_LIST_VIEW;
      stash.sharedSection = SEARCH_RESULTS;
    } else {
      stash.component = '';
      stash.pageType = COLLECTION_PAGETYPE_INTEGRATED_BRANDS;
    }

    if (parent === IRG) {
      eventInfo.eventName = RECS_CART_ADDITION_INFO;
      eventInfo.eventMode = STASH;
      stash.section = 'dyn prod rec';
      stash.pageType = pageType;
      stash.sharedSection = NOT_APPLICABLE;
      stash.recommendation = {
        recommendationType: 'irg',
        pageType,
        displayPosition: podPosition,
        anchorProductSku: pageItemId,
        featureVersion: NOT_APPLICABLE,
        strategy: irgSectionName || NOT_APPLICABLE,
        recommendationVersion: NOT_APPLICABLE,
        recommendedProduct: NOT_APPLICABLE,
        interval: NOT_APPLICABLE
      };
    }

    if (parent === SBR_COLLECTION || isCollection) {
      stash.pageType = `collections:${getCollectionPageType({ type: collectionType })}`;
      stash.sharedSection = parent === IBT_BATH_COLLECTION
        ? `${primaryCategory?.toLowerCase()}>${collectionName}` : null;
      stash.section = parent === SBR_COLLECTION ? COLLECTION_PAGETYPE_SPACE : 'gridview';
      stash.item = items.map((item) => ({
        index: '',
        productId: item.productId
      }));
      extraEventData.eventMode = STASH;
      if ([CURATION_CAROUSEL, VISUAL_RECOMMENDATION].includes(parent)) {
        stash.component = 'carousel';
      }
    }

    if ([
      RECS_PRODUCT_POD_CAROUSEL,
      INSTANT_RECS,
      PLP_RECS_PLACEHOLDER,
      SUPPLEMENTAL_RECS,
      RECS_DTS_PRODUCT_POD_CAROUSEL,
      RECENTLY_VIEWED
    ].some((element) => parent.startsWith(element))) {
      const pageTypeValue = (typeof pageType === 'undefined' || pageType === 'unknown' || !pageType)
        ? currentPageType : pageType;
      const addtocartRecommendationEvent = {
        category: {
          primaryCategory: STASH
        },
        eventInfo: {
          eventName: RECS_CART_ADDITION_INFO
        },
        eventMode: STASH,
        stash: {
          component: PRODUCT_POD,
          item: items,
          pageType: pageTypeValue,
          strategy: strategy || STRATEGY,
          section: SECTION,
          target: ATC,
          recommendation: {
            recommendationType: scheme,
            pageType: pageTypeValue,
            displayPosition: podPosition,
            anchorProductSku: anchorProduct || pageItemId || NOT_APPLICABLE,
            featureVersion: NOT_APPLICABLE,
            strategy: NOT_APPLICABLE,
            recommendationVersion: RECOMMENDATION_VERSION,
            recommendedProduct: NOT_APPLICABLE,
            interval: NOT_APPLICABLE,
            sponsoredProduct: isSponsored ? SP : NOT_APPLICABLE
          }
        }
      };
      if (isHomepage) {
        addtocartRecommendationEvent.stash.pageType = MY_HOMEPAGE;
        addtocartRecommendationEvent.stash.recommendation.pageType = MY_HOMEPAGE;
      }
      digitalData.event = digitalData.event || [];
      digitalData.event.push(addtocartRecommendationEvent);
      if (sponsoredProduct && sponsoredMetadata) {
        sponsoredClickThroughAnalytics(sponsoredMetadata);
      }
      return;
    }

    // this conditional handles the newer 'cart addition info' contract
    // use this block if your spec requires 'cart addition info' instead of 'cart add location'
    if (parent === SPECIAL_BUY_BANNER_CAROUSEL) {
      stash.item = items.map((item) => ({
        productInfo: {
          sku: item.productId
        },
      }));
      eventInfo.eventName = CART_ADDITION_INFO;
    }

    if (parent === MORE_IN_THIS_COLLECTION_SECTION) {
      stash.section = 'brand collection';
    }

    if (parent.includes(DISCOVERY_ZONES) && scheme === DISCOVERY_ZONES_BUYITAGAIN) {
      extraEventData.eventMode = STASH;
      eventInfo.eventName = CART_ADDITION_INFO;
      stash.component = PRODUCT_POD;
      stash.item = items.map((item) => ({
        displayPosition: 'n/a',
        productInfo: {
          sku: item.productId
        },
      }));
      stash.section = 'buy it again';
      stash.sharedSection = 'n/a';
      stash.target = ATC;
      stash.pageType = pageType;
    }

    if (pageType === MY_LIST_DETAILS_PAGE_TYPE) {
      stash.sharedSection = 'n/a';
      stash.section = 'n/a';
      stash.component = 'n/a';
    }

    if (isTableView && (digitalData?.page?.displayAttributes?.viewType === 'tableview')) {
      stash.section = TABLEVIEW;
    }

    const ddoEvent = {
      eventInfo,
      category: {
        primaryCategory: STASH
      },
      stash,
      ...extraEventData
    };
    digitalData.event.push(ddoEvent);

    if (sponsoredClickBeacons.length > 0) {
      sponsoredTracking(sponsoredClickBeacons);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-pod-v7.services-button.click', ({ output = {} }) => {
    const { eventName = '', formName = '' } = output;
    const ddoEvent = {
      eventInfo: {
        eventName
      },
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      form: {
        formName,
        formType: 'installation services and repair'
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-pod-v7.compare', ({ output = {} }) => {
    const {
      podAction,
      podAnchorSku,
      parent
    } = output;

    const {
      currentPage,
      pageSize,
    } = getState() || {};

    const podType = 'product';

    const productData = getProductData(podAnchorSku, parent);

    const {
      position,
      podInteractionNumber,
      sponsoredValues
    } = productData || {};

    const isSponsored = sponsoredValues?.isSponsored || false;

    const ddoEvent = {
      stash: {
        podPageNumber: currentPage,
        podPosition: position,
        podType,
        podPageResults: pageSize,
        podAction,
        podInteractionNumber,
        podAnchorSku,
        sponsoredProduct: !!(isSponsored),
        customProduct: NOT_CUSTOM_CATALOG // TODO: Update this to get from product pod once B2B CPO is implemented
      },
      eventInfo: {
        eventName: 'product pod click'
      },
      category: {
        primaryCategory: 'stash'
      },
      eventMode: 'stash',
      isReported: true,
      sourceIndex: 1,
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
    updatePodInteractionCount(podAnchorSku, parent);
  });
};