/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import digitalData from 'digital-data';

let override = null;

// ---------- from thd-analytics-utils  ------------------------------------------------------------ //

const EVENTS = {
  HDPP_LINK_DISPLAYED: 'hdpp-link-displayed',
  STANDARD_CONFIRMATION: 'standard-confirmation',
  TOOL_RENTAL_CONFIRMATION: 'toolrental-confirmation',
  AMERIGAS_CONFIRMATION: 'amerigas-confirmation',
  GIFT_CARD_RELOAD_CONFIRMATION: 'giftcardReload-confirmation',
  PAGE_LOAD_ERROR: 'page-load-error',
  CREATE_ACCOUNT_AT_CHECKOUT_SUCCESS: 'create-account-at-checkout-success',
};

const SELECTED_FFM_TYPES = {
  BOPIS: 'bopis',
  BOSS: 'boss',
  STH: 'sth',
  BODFS: 'express delivery',
  APPL_DELIV: 'direct delivery'
};

const ADDON_TYPES = {
  HDPP: 'protectionPlan',
  ASSEMBLY: 'Assembly',
  FREE_ASSEMBLY: 'freeAssemblyAtStore',
  INSTALL: 'Install',
  PRO_INSTALL: 'PRInstall',
  PAINTER: 'Painter',
  SUBSCRIPTION: 'subscription'
};

const APPLE_PAY = 'APPLE_PAY';

const CART_VIEW_PREFERENCE = 'cart.view-preference';

const GIFT_CARD = 'GIFT_CARD';

export const setAnalytics = () => {
  if (navigator?.permissions?.query) {
    try {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        digitalData.user = {
          ...digitalData.user,
          localization: {
            ...digitalData?.user?.localization,
            gpsPermission: result?.state?.length ? 'gps access ' + result?.state : null
          }
        };
      });
    } catch (err) {
      console.error(err);
    }
  }

  window._T = window._T || {};

  if (typeof window._T.pageData === 'undefined') {
    window._T.pageData = [];
  }
  setTimeout(() => {
    window._T.pageData.push(digitalData);
  }, 4000);
};

// ---------- START OF UTILS FUNCTIONS  ------------------------------------------------------------ //

const publishPageLoadAnalytics = ({ eventObject = {} }) => {
  try {
    digitalData.page = eventObject?.page || {};
    digitalData.transaction = eventObject?.transaction || {};
    digitalData.legacy = eventObject?.legacy || {};

    // only set this if we set this flag here, otherwise instant-checkout component will set it
    if (eventObject?.content?.feature?.instantCheckoutEnroll) {
      digitalData.content = eventObject?.content || {};
    }

    // if override function exists
    if (override && typeof override === 'function') {
      override(eventObject);
    }
    setAnalytics(digitalData);
  } catch (error) {
    console.error('Error in publishPageLoadAnalytics()', error);
  }
};

const publishEvent = ({ eventObject }) => {
  try {
    // if override function exists
    if (override && typeof override === 'function') {
      override(eventObject);
    }
    digitalData.event.push(eventObject);
  } catch (error) {
    console.error('Error in publishEvent()', error);
  }
};

const setPageInfo = (eventObject, variables = {}) => {
  eventObject.page = {
    ...eventObject.page,
    myAccount: {
      savedPaymentMethod: '',
    }
  };
  eventObject.page.pageInfo = {
    abTest: '',
    platform: 'nucleus',
    ...variables
  };
};

const setCategory = (eventObject, variables = {}) => {
  eventObject.page = {
    ...eventObject.page
  };
  eventObject.page.category = {
    primaryCategory: 'order confirmation',
    subCategory1: '',
    subCategory2: '',
    ...variables
  };
};

const setForm = (eventObject, variables = {}) => {
  eventObject.page = {
    ...eventObject.page
  };
  eventObject.page.form = {
    rentalLeadID: variables?.orderId || ''
  };
};

const setProfile = (eventObject, variables = {}) => {
  eventObject.transaction = {
    ...eventObject.transaction
  };
  eventObject.transaction.profile = {
    ...variables
  };
};

const getSiteIntegration = ({ domain }) => {
  try {
    if (domain?.toUpperCase() === 'AAFES') {
      return 'aafes';
    }
    if (domain?.toUpperCase() === 'NEX') {
      return 'nex';
    }
  } catch (error) {
    console.error(error);
  }
  return '';
};

const bopis = (fulfillment) => {
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option?.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service?.type === 'bopis');
  return bopisFulfillment || null;
};
const boss = (fulfillment) => {
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option?.type === 'pickup');
  const bossFulfillment = (pickupService?.services || []).find((service) => service?.type === 'boss');
  return bossFulfillment || null;
};
const sth = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option?.type === 'delivery');
  const sthFulfillment = (deliveryService?.services || []).find((service) => service?.type === 'sth');
  return sthFulfillment || null;
};
const bodfs = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option?.type === 'delivery');
  const bodfsFulfillment = (deliveryService?.services || []).find((service) => service?.type === 'express delivery');
  return bodfsFulfillment || null;
};
const applianceDelivery = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option?.type === 'delivery');
  const bodfsFulfillment = (deliveryService?.services || []).find((service) => service?.type === 'direct delivery');
  return bodfsFulfillment || null;
};

const getAddonFromItem = ({ item, addonType }) => {
  try {
    if (!item?.product?.addons || !addonType) { return false; }
    return (item?.product?.addons?.find((addon) => addon?.type === addonType));
  } catch (error) {
    console.error('Error occurred in getAddonFromItem()', error);
    return {};
  }
};

const isProReferralAddon = (addonType = '') => {
  return (addonType === ADDON_TYPES.PRO_INSTALL || addonType === ADDON_TYPES.PAINTER);
};

const getItemSkuForAnalytics = ({ item, addon }) => {
  try {
    const ASSEMBLY_SKU = '764092'; // <-- must return this as itemSKU for bopis or bodfs free store assembly

    switch (addon?.type) {
    case ADDON_TYPES.FREE_ASSEMBLY:
      return ASSEMBLY_SKU;
    case ADDON_TYPES.HDPP:
    case ADDON_TYPES.ASSEMBLY:
    case ADDON_TYPES.INSTALLL:
      return addon?.configAttr || '';
    case ADDON_TYPES.PRO_INSTALL:
    case ADDON_TYPES.PAINTER:
      return item?.product?.itemId || '';
    default:
      return item?.product?.itemId || '';
    }
  } catch (err) {
    console.log(err);
  }
  return '';
};

const getFfmMethodForAnalytics = (ffmMethod) => {
  switch (ffmMethod) {
  case SELECTED_FFM_TYPES.BOPIS:
    return 'bopis';
  case SELECTED_FFM_TYPES.BOSS:
    return 'boss';
  case SELECTED_FFM_TYPES.STH:
    return 'sth';
  case SELECTED_FFM_TYPES.BODFS:
    return 'bodfs';
  case SELECTED_FFM_TYPES.APPL_DELIV:
    return 'appl';
  default:
    return '';
  }
};

const getFulfillmentMethodForAddons = ({ item, addon }) => {
  const isFreeAssemblyAtStore = addon?.type === ADDON_TYPES.FREE_ASSEMBLY;
  const ffmMethod = isFreeAssemblyAtStore
    ? getFfmMethodForAnalytics(item?.selectedFulfillment) // could be 'bopis' or 'bodfs'
    : 'sth';
  return ffmMethod;
};

const getSubscriptionFrequency = (frequency = '') => {
  try {
    const frequencyMap = {
      '1_3': '1 months', // every 1 month
      '2_3': '2 months', // every 2 months, etc
      '3_3': '3 months',
      '4_3': '4 months',
      '5_3': '5 months',
      '6_3': '6 months',
      '9_3': '9 months',
      '12_3': '12 months'
    };
    return frequency ? frequencyMap[frequency] : '';
  } catch (error) {
    console.error(error);
    return '';
  }
};

const getPickUpOption = ({ item, orderConfirmation }) => {
  try {
    const curbside = 'curbside';
    const inStore = 'in-store';
    const selectedFFM = item?.selectedFulfillment || '';
    const fulfillment = item?.product?.fulfillment || {};
    const isBopis = selectedFFM === SELECTED_FFM_TYPES.BOPIS;
    const isBoss = selectedFFM === SELECTED_FFM_TYPES.BOSS;
    let pickupStoreIdForItem = '';

    if (isBopis) {
      const bopisService = bopis(fulfillment);
      pickupStoreIdForItem = bopisService?.locations?.[0]?.locationId || bopisService?.locations?.[1]?.locationId || '';
    } else if (isBoss) {
      const bossService = boss(fulfillment);
      pickupStoreIdForItem = bossService?.locations?.[0]?.locationId || bossService?.locations?.[1]?.locationId || '';
    }

    const pickupGrouping = orderConfirmation?.itemGrouping?.byFulfillment?.pickup || [];
    const itemPickupLocationDetails = pickupGrouping?.find((store) => {
      return store?.location?.locationId === pickupStoreIdForItem;
    });

    return itemPickupLocationDetails?.location?.method === 'CURBSIDE' ? curbside : inStore;
  } catch (error) {
    console.error(error);
  }
  return '';
};

const getStoreOrZip = ({ item }) => {
  try {
    const selectedFFM = item?.selectedFulfillment || '';
    const fulfillment = item?.product?.fulfillment || {};
    let locations = [];

    switch (selectedFFM) {
    case SELECTED_FFM_TYPES.BOPIS:
      const bopisService = bopis(fulfillment);
      locations = bopisService?.locations || [];
      const isBopisPrimaryStoreOOS = !locations?.[0]?.inventory?.isInStock;
      if (locations?.length > 1 && isBopisPrimaryStoreOOS) {
        return locations?.[1]?.locationId;
      }
      return locations?.[0]?.locationId;

    case SELECTED_FFM_TYPES.BOSS:
      const bossService = boss(fulfillment);
      locations = bossService?.locations || [];
      const isBossPrimaryStoreOOS = !locations?.[0]?.inventory?.isInStock;
      // if nearbyStore
      if (locations?.length > 1 && isBossPrimaryStoreOOS) {
        return locations?.[1]?.locationId;
      }
      return locations?.[0]?.locationId;

    case SELECTED_FFM_TYPES.BODFS:
      const bodfsService = bodfs(fulfillment);
      locations = bodfsService?.locations || [];
      return locations?.[0]?.zipCode;

    case SELECTED_FFM_TYPES.APPL_DELIV:
      const applDeliveryService = applianceDelivery(fulfillment);
      locations = applDeliveryService?.locations || [];
      return locations?.[0]?.zipCode;

    default:
      break;
    }
  } catch (error) {
    console.error(error);
  }
  return '';
};

/* const buildItemETA = (item) => {
  let eta = {};

  const fulfillment = item?.product?.fulfillment || {};
  const selectedFFM = item?.selectedFulfillment || '';

  switch (selectedFFM) {
  case SELECTED_FFM_TYPES.BOSS:
    const bossService = boss(fulfillment);
    eta = {
      bossStartDate: bossService?.deliveryDates?.startDate || 'NA',
      bossEndDate: bossService?.deliveryDates?.endDate || 'NA'
    };
    break;
  case SELECTED_FFM_TYPES.STH:
    const sthService = sth(fulfillment);
    eta = {
      sthStartDate: sthService?.deliveryDates?.startDate || 'NA',
      sthEndDate: sthService?.deliveryDates?.endDate || 'NA'
    };
    break;
  case SELECTED_FFM_TYPES.BODFS:
    const bodfsService = bodfs(fulfillment);
    eta = {
      bodfsStartDate: bodfsService?.deliveryDates?.startDate?.split('T')?.[0] || 'NA',
      bodfsEndDate: bodfsService?.deliveryDates?.endDate?.split('T')?.[0] || 'NA'
    };
    break;
  case SELECTED_FFM_TYPES.APPL_DELIV:
    const applDeliveryService = applianceDelivery(fulfillment);
    eta = {
      applStartDate: applDeliveryService?.deliveryDates?.startDate || 'NA',
      applEndDate: applDeliveryService?.deliveryDates?.endDate || 'NA'
    };
    break;
  default:
    break;
  }

  return eta;
}; */

const buildItemFulfillment = ({ item, orderConfirmation }) => {
  const selectedFulfillment = item?.selectedFulfillment;
  const isPickupFfm = selectedFulfillment === SELECTED_FFM_TYPES.BOPIS
    || selectedFulfillment === SELECTED_FFM_TYPES.BOSS;

  const itemFulfillment = {
    method: getFfmMethodForAnalytics(selectedFulfillment),
    // backOrder: item?.product?.fulfillment?.backordered ? 'yes' : 'no',
  };

  if (isPickupFfm) {
    itemFulfillment.store = getStoreOrZip({ item });
    itemFulfillment.pickupOption = getPickUpOption({ item, orderConfirmation });
  } else if (selectedFulfillment !== SELECTED_FFM_TYPES.STH) {
    itemFulfillment.zip = getStoreOrZip({ item });
  }

  return itemFulfillment;
};

const getProductName = (item) => {
  const brandName = item?.product?.identifiers?.brandName || '';
  const productLabel = item?.product?.identifiers?.productLabel || '';
  if (brandName && productLabel) {
    return brandName + ' ' + productLabel;
  }
  return brandName || productLabel || '';
};

const getItemDiscountInfo = ({ lineItemId = '', promoItemsArray = [] }) => {
  try {
    if (!lineItemId || !promoItemsArray?.length) {
      return '';
    }
    const promoItemData = promoItemsArray?.find((promoItem) => promoItem?.appliedOn === lineItemId);
    const appliedDiscountNumber = Number(promoItemData?.appliedDiscount) || '';
    return appliedDiscountNumber
      ? (appliedDiscountNumber * -1)?.toFixed(2) || '' // transform -1.23 into '1.23' for analytics
      : '';
  } catch (error) {
    console.log(error);
  }
  return '';
};

const getAdjustment = (orderConfirmation, type) => {
  return orderConfirmation?.totals?.adjustments?.find((adjustment) => adjustment?.type === type) || {};
};

const getAdjustmentAmountString = (orderConfirmation, type) => {
  return getAdjustment(orderConfirmation, type)?.amount?.toFixed(2) || '0.00';
};

const getPromoCodeData = ({ orderConfirmation }) => {
  try {
    if (orderConfirmation?.promos?.length) {
      return orderConfirmation?.promos?.find((promo) => promo?.promoCode
        && promo?.promoItems?.length);
    }
  } catch (error) {
    console.error(error);
  }
  return {};
};

const isApplePayOrder = (orderConfirmation = {}) => {
  return orderConfirmation?.payments?.[0]?.wallet?.type?.toUpperCase() === APPLE_PAY;
};

const getTransactionPrice = ({ orderConfirmation = {}, isGiftCardReload = false, isStandard = false }) => {
  const tax = getAdjustmentAmountString(orderConfirmation, 'totalSalesTax');
  const showBasePrice = !isGiftCardReload;
  const basePriceValue = (orderConfirmation?.totals?.totalWithNoDiscount + orderConfirmation?.totals?.totalDiscount);
  const _basePrice = basePriceValue?.toFixed(2) || '0.00';
  const total = orderConfirmation?.totals?.total?.toFixed(2) || '0.00';
  const totalShippingCharge = getAdjustmentAmountString(orderConfirmation, 'totalShippingCharge');
  const bodfsShippingCharge = getAdjustmentAmountString(orderConfirmation, 'deliveryCharge');
  const promoCodeData = getPromoCodeData({ orderConfirmation });

  let discountAmount = '';
  if (promoCodeData?.appliedDisc) {
    discountAmount = (promoCodeData?.appliedDisc * -1)?.toFixed(2); // transform -1.23 into '1.23' for analytics
  }

  return {
    ...(showBasePrice && { basePrice: _basePrice }),
    ...(isApplePayOrder(orderConfirmation) && { paymentMethod: ['applepay'] }),
    shipping: totalShippingCharge,
    bodfsShipping: bodfsShippingCharge,
    tax: tax || '0.00',
    transactionTotal: total || '0.00',
    discountAmount,
    discountCode: discountAmount && promoCodeData?.promoCode
      ? promoCodeData?.promoCode
      : '',
  };
};

const getToolRentalTransactionPrice = (orderConfirmation) => {
  const { deposit, feeTotal, taxTotal } = orderConfirmation?.rentalEstimate?.tools?.[0] || {};
  const tax = taxTotal?.toFixed(2) || '0.00';
  const _basePrice = feeTotal?.toFixed(2) || '0.00';
  const total = (feeTotal + taxTotal)?.toFixed(2) || '0.00';
  return {
    basePrice: _basePrice,
    shipping: '0.00',
    bodfsShipping: '0.00',
    tax: tax || '0.00',
    transactionTotal: total || '0.00',
    ...(isApplePayOrder(orderConfirmation) && { paymentMethod: ['applepay'] }),
  };
};

const setTransactionPrice = (eventObject, variables = {}) => {
  eventObject.transaction.price = {
    ...eventObject.price
  };
  eventObject.transaction.price = {
    ...variables
  };
};

const setTransaction = (eventObject, variables = {}) => {
  eventObject.transaction = {
    ...eventObject.transaction,
    ...variables
  };
};

const transformAddonToAnalyticsStructure = ({ item, orderConfirmation }) => {
  const addonsForAnalytics = [];
  try {
    const addons = item?.product?.addons || [];

    if (addons?.length) {
      addons.forEach((addon) => {
        const isValidAddonForAnalytics = [
          ADDON_TYPES.HDPP,
          ADDON_TYPES.PRO_INSTALL,
          ADDON_TYPES.PAINTER,
          ADDON_TYPES.FREE_ASSEMBLY
        ].includes(addon?.type);
        const isHdppAddon = addon?.type === ADDON_TYPES.HDPP;
        const isHddpForAppl = isHdppAddon && item?.selectedFulfillment === SELECTED_FFM_TYPES.APPL_DELIV;
        const isAddonSelected = addon?.selected || false;
        const isProReferral = isProReferralAddon(addon?.type);

        // only publish *SELECTED* addon analytics, UNLESS that addon is Pro Referral.
        // We always allow pro referral to publish
        if (isValidAddonForAnalytics && !isHddpForAppl && (isAddonSelected || isProReferral)) {
          const price = addon?.totalPrice ? addon?.totalPrice?.toFixed(2) : '0.00';
          const itemId = getItemSkuForAnalytics({ item, addon });

          const analyticsObject = {
            quantity: addon?.quantity ? addon?.quantity?.toString() : '1',
            price: {
              basePrice: price
            },
            productInfo: {
              sku: itemId
            },
            fulfillment: {
              method: getFulfillmentMethodForAddons({ item, addon })
            }
          };
          const isFreeAssemblyAtStore = addon?.type === ADDON_TYPES.FREE_ASSEMBLY;

          if (isHdppAddon) {
            analyticsObject.price = {
              ...analyticsObject.price,
              discountAmount: '',
              discountCode: ''
            };
            const classNumber = item?.product?.identifiers?.productClass || '';
            const deptNumber = item?.product?.identifiers?.productDepartment || '';
            const subClass = item?.product?.identifiers?.productSubClass || '';
            analyticsObject.productInfo = {
              ...analyticsObject.productInfo,
              ...(classNumber && { class: classNumber }),
              ...(deptNumber && { department: deptNumber }),
              ...(subClass && { subclass: subClass }),
              superSKU: itemId || '',
              productName: addon?.description || '',
              manufacturer: addon?.brandName || '',
              serviceLevel: ''
              // subCategory2: item?.product?.identifiers?.productLabel || '',
            };
            // analyticsObject.fulfillment.backOrder = item?.product?.fulfillment?.backordered ? 'yes' : 'no';
          } else if (isFreeAssemblyAtStore) {
            analyticsObject.productInfo.assembly = 'free in-store';
          } else if (isHddpForAppl) {
            // eslint-disable-next-line max-len
            analyticsObject.price.applDeliveryFee = orderConfirmation?.totals?.applianceDeliveryCharge?.toFixed(2) || '';
          } else if (isProReferral) {
            const isProReferralSelected = addon?.selected;
            if (isProReferralSelected) {
              analyticsObject.productInfo.proReferralSelected = true;
            } else {
              analyticsObject.productInfo.proReferralDisplayed = true;
            }
          }

          addonsForAnalytics.push(analyticsObject);
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
  return addonsForAnalytics;
};

function getAnalyticsServiceLevel(item) {
  const serviceLevelDescription = item?.product?.fulfillment?.fulfillmentOptions?.[0]
    ?.services?.[0]?.selectedShipMode?.desc || '';
  let serviceLevelAnalyticsValue = '';
  try {
    if (/Signature/i.test(serviceLevelDescription)) {
      serviceLevelAnalyticsValue = 'basic signature';
    } else if (/Threshold/i.test(serviceLevelDescription)) {
      serviceLevelAnalyticsValue = 'threshold';
    } else if (/Room of Choice/i.test(serviceLevelDescription)) {
      serviceLevelAnalyticsValue = 'room of choice';
    } else if (/Curbside/i.test(serviceLevelDescription)) {
      serviceLevelAnalyticsValue = 'curbside';
    } else if (/Professional/i.test(serviceLevelDescription)) {
      serviceLevelAnalyticsValue = 'professional delivery';
    } else if (/Outdoor/i.test(serviceLevelDescription)) {
      serviceLevelAnalyticsValue = 'outdoor delivery';
    } else if (/Bulk/i.test(serviceLevelDescription)) {
      serviceLevelAnalyticsValue = 'bulk';
    } else if (/Basic/i.test(serviceLevelDescription)) {
      serviceLevelAnalyticsValue = 'basic';
    } else {
      serviceLevelAnalyticsValue = serviceLevelDescription ? serviceLevelDescription?.replace(/[()]/g, '') : '';
    }
  } catch (err) {
    console.error('OPC: Error getting Analytics version of Service Level', err);
  }
  return serviceLevelAnalyticsValue;
}

const getItem = ({
  item,
  orderConfirmation,
  isStandard = false,
  isAmerigas = false,
  isToolRental = false,
  isGiftCardReload = false
}) => {
  const isGiftCardItem = item?.product?.identifiers?.productType?.toUpperCase() === GIFT_CARD;
  const showSuperSku = !isGiftCardItem && !isGiftCardReload;
  const showManufacturer = !isGiftCardReload;
  const itemId = item?.product?.identifiers?.itemId || '';
  const totalPrice = item?.product?.pricing?.total?.toFixed(2) || '';
  const classNumber = item?.product?.identifiers?.productClass || '';
  const deptNumber = item?.product?.identifiers?.productDepartment || '';
  const subClass = item?.product?.identifiers?.productSubClass || '';
  const showCustomCatalog = item?.product?.identifiers?.productCategory?.toLowerCase()?.includes('custom');
  const promoCodeData = getPromoCodeData({ orderConfirmation });
  let discountAmount = '';
  if (promoCodeData?.promoCode) {
    discountAmount = getItemDiscountInfo({
      lineItemId: item?.id, promoItemsArray: promoCodeData?.promoItems
    });
  }
  const itemAnalytics = {
    quantity: item?.quantity || '',
    price: {
      basePrice: totalPrice,
      discountCode: discountAmount ? promoCodeData?.promoCode : '',
      discountAmount
    },
    productInfo: {
      ...(classNumber && { class: classNumber }),
      ...(deptNumber && { department: deptNumber }),
      ...(subClass && { subclass: subClass }),
      serviceLevel: getAnalyticsServiceLevel(item),
      sku: itemId || '',
      ...(showSuperSku && { superSKU: itemId }),
      productName: item?.product?.identifiers?.productLabel || '',
      ...(showManufacturer && { manufacturer: item?.product?.identifiers?.brandName || '' }),
      ...(showCustomCatalog && { customProduct: 'custom catalog' })
    },
    fulfillment: buildItemFulfillment({ item, orderConfirmation })
  };

  const isGiftCard = item?.product?.identifiers?.productType?.toUpperCase() === GIFT_CARD;

  if (isGiftCard) {
    itemAnalytics.price.giftCardPrice = totalPrice;
    itemAnalytics.price.basePrice = '0.00';
  }

  if (isGiftCardReload) {
    itemAnalytics.price.giftCardReloadPrice = totalPrice;
    itemAnalytics.price.basePrice = '0.00';
    itemAnalytics.fulfillment = {
      method: 'sth'
    };
  }

  const subscriptionAddonData = getAddonFromItem({ item, addonType: ADDON_TYPES.SUBSCRIPTION });
  const isSubscriptionAddonSelected = subscriptionAddonData?.selected || false;
  if (subscriptionAddonData && isSubscriptionAddonSelected) {
    itemAnalytics.subscription = {
      frequency: getSubscriptionFrequency(subscriptionAddonData?.configAttr)
    };
  }

  // analytics team doesnt want to have ETA for any order conf items
  // if (item?.selectedFulfillment !== SELECTED_FFM_TYPES.BOPIS) {
  //   itemAnalytics.productInfo.eta = buildItemETA(item);
  // }

  if (isToolRental) {
    itemAnalytics.productInfo = {
      productName: getProductName(item),
      serviceLevel: itemAnalytics?.productInfo?.serviceLevel,
      sku: itemAnalytics?.productInfo?.sku,
    };

    const { basePrice = '0.00' } = getToolRentalTransactionPrice(orderConfirmation);
    itemAnalytics.price.basePrice = basePrice;
    itemAnalytics.fulfillment.zip = ''; // because analytics team wanted it
    itemAnalytics.subscription = {
      frequency: ''
    };
  }

  return itemAnalytics;
};

const getItems = ({
  orderConfirmation,
  isStandard = false,
  isGiftCardReload = false,
  isAmerigas = false,
  isToolRental = false
}) => {
  const itemsArray = [];
  // eslint-disable-next-line no-unused-expressions
  orderConfirmation?.items?.forEach((item) => {
    itemsArray.push(getItem({
      item,
      orderConfirmation,
      isStandard,
      isAmerigas,
      isToolRental,
      isGiftCardReload
    }));

    const processAddonArray = transformAddonToAnalyticsStructure({ item, orderConfirmation });
    if (processAddonArray?.length) {
      processAddonArray.forEach((addon) => {
        itemsArray.push(addon);
      });
    }
  });

  return itemsArray;
};

const setCart = (eventObject, variables = {}) => {
  eventObject.cart = {
    ...eventObject.cart,
    ...variables
  };
};

const getProfileAddress = (orderConfirmation) => {
  return {
    line1: orderConfirmation?.payments?.[0]?.address?.line1 || '',
    line2: orderConfirmation?.payments?.[0]?.address?.line2 || '',
    city: orderConfirmation?.payments?.[0]?.address?.city || '',
    stateProvince: orderConfirmation?.payments?.[0]?.address?.state || '',
    postalCode: orderConfirmation?.payments?.[0]?.address?.zipCode || '',
    country: orderConfirmation?.payments?.[0]?.address?.country || ''
  };
};

const getAdditionalEmail = (orderConfirmation) => {
  return orderConfirmation?.communication?.secondaryEmailId ? 'yes' : 'no';
};

const setAuroraData = (eventObject) => {
  if (eventObject?.cart?.cartID) {
    eventObject.cart.attributes = {
      ...eventObject.cart.attributes,
      OMS: 'aurora',
    };
  } else if (eventObject?.transaction?.transactionID) {
    eventObject.transaction.attributes = {
      ...eventObject.transaction.attributes,
      OMS: 'aurora',
    };
  }
};

const getCartViewType = () => {
  try {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(CART_VIEW_PREFERENCE) === 'List' ? 'list' : 'detail';
    }
  } catch (error) {
    console.error(error);
  }
  return 'detail';
};

const setContent = (eventObject, variables = {}, orderConfirmation, customer, isStandard) => {
  eventObject.content = {
    ...eventObject.content,
    ...variables
  };

  const isGuestUser = orderConfirmation?.customer?.type?.toUpperCase() === 'G';
  const isB2BCustomer = customer?.isB2BCustomer || false;
  const isUserInstantCheckoutEligibile = !isGuestUser && !isB2BCustomer;

  // the instant checkout component sets the instantCheckoutEnroll flag to 'y' if the user is eligible
  if (!isStandard || !isUserInstantCheckoutEligibile) {
    eventObject.content = {
      ...eventObject.content,
      feature: {
        instantCheckoutEnroll: 'n',
      }
    };
  }
};

const initAnalytics = () => {
  digitalData.init();
};

// ---------- END OF UTILS FUNCTIONS ------------------------------------------------------------ //

const amerigasConfirmation = ({ orderConfirmation, customer = {}, poJobNameType }) => {
  initAnalytics();
  const eventObject = {};
  const itemId = orderConfirmation?.itemModels?.[0]?.itemId || '';
  const amerigasSubCategory = 'checkout>purchase confirmation';
  const orderId = orderConfirmation?.orderId || '';
  const basePrice = orderConfirmation?.estimateModel?.tools?.[0]?.feeTotal || '';
  const tax = orderConfirmation?.estimateModel?.tools?.[0]?.taxTotal || '';

  setPageInfo(eventObject, {
    pageLoadEvent: 'purchase',
    pageName: 'checkout>purchase confirmation',
    pageType: 'checkout',
    abTest: 'confirmation:version:react',
    pageTemplate: 'default',
  });

  setCategory(eventObject, {
    primaryCategory: 'checkout',
    subCategory1: amerigasSubCategory,
    subCategory2: amerigasSubCategory
  });

  // setCart(eventObject, {
  //   price: {}
  // });

  setTransaction(eventObject, {
    transactionID: orderId || '',
    item: getItems({ orderConfirmation, isAmerigas: true }),
    uniqueSkuCount: orderConfirmation?.items?.length || '',
    quantityTotal: orderConfirmation?.itemCount,
    attributes: {
      poJobName: poJobNameType || 'na',
    },
  });

  setTransactionPrice(eventObject, {
    ...getTransactionPrice({ orderConfirmation })
  });

  setAuroraData(eventObject);

  setProfile(eventObject, {
    additionalEmail: getAdditionalEmail(orderConfirmation),
    address: getProfileAddress(orderConfirmation)
  });

  setContent(eventObject, {}, orderConfirmation, customer);

  publishPageLoadAnalytics({ eventObject });
};

const toolRentalConfirmation = ({ orderConfirmation, poJobNameType }) => {
  initAnalytics();
  const eventObject = {};
  const itemId = orderConfirmation?.items?.[0]?.product?.itemId || '';
  const toolrentalCategory = 'product rental>' + itemId + '>reserve confirm';
  const orderId = orderConfirmation?.orderId || '';

  setPageInfo(eventObject, {
    abTest: '',
    pageName: toolrentalCategory,
    pageType: 'tool:productRental:reserveConfirm',
    pageLoadEvent: 'product rental confirmation',
  });

  setCategory(eventObject, {
    primaryCategory: toolrentalCategory,
    subCategory1: toolrentalCategory,
    subCategory2: toolrentalCategory
  });

  setTransaction(eventObject, {
    transactionID: orderId || '',
    item: getItems({ orderConfirmation, isToolRental: true }),
    attributes: {
      poJobName: poJobNameType || 'na',
    },
  });

  setTransactionPrice(eventObject, {
    ...getToolRentalTransactionPrice(orderConfirmation)
  });

  setProfile(eventObject, {
    address: getProfileAddress(orderConfirmation)
  });

  setForm(eventObject, { orderId });
  publishPageLoadAnalytics({ eventObject });
};

const standardConfirmation = ({ orderConfirmation, customer = {}, poJobNameType }) => {
  initAnalytics();
  const eventObject = {};
  const orderId = orderConfirmation?.orderId || '';
  const { isB2BCustomer = false } = customer;

  setPageInfo(eventObject, {
    pageLoadEvent: 'purchase',
    pageName: 'checkout>purchase confirmation',
    pageType: 'checkout',
    abTest: 'confirmation:version:react',
    pageTemplate: 'default'
  });

  const subCategory = 'checkout>purchase confirmation';

  setCategory(eventObject, {
    primaryCategory: 'checkout',
    subCategory1: subCategory,
    subCategory2: subCategory
  });

  const transactionItems = getItems({ orderConfirmation, isStandard: true });

  const uniqueSkuCount = new Set((transactionItems || [])?.map((item) => item?.productInfo?.sku || ''))?.size || '';

  setTransaction(eventObject, {
    transactionID: orderId || '',
    ...(isB2BCustomer && { cartViewType: getCartViewType() }),
    item: transactionItems,
    uniqueSkuCount: uniqueSkuCount || '',
    quantityTotal: orderConfirmation?.itemCount,
    attributes: {
      poJobName: poJobNameType || 'na',
    },
  });

  setTransactionPrice(eventObject, {
    ...getTransactionPrice({ orderConfirmation, isStandard: true })
  });

  setAuroraData(eventObject);

  setProfile(eventObject, {
    additionalEmail: getAdditionalEmail(orderConfirmation),
    address: getProfileAddress(orderConfirmation)
  });

  setContent(eventObject, {}, orderConfirmation, customer, true);

  publishPageLoadAnalytics({ eventObject });
};

const giftCardReloadConfirmation = ({ orderConfirmation, customer = {} }) => {
  initAnalytics();
  const eventObject = {};
  const orderId = orderConfirmation?.orderId || '';

  setPageInfo(eventObject, {
    pageLoadEvent: 'purchase',
    pageName: 'gift cards>check balance and reload>reload successful',
    pageType: 'checkout',
    abTest: 'confirmation:version:react',
    pageTemplate: 'default'
  });

  setCategory(eventObject, {
    primaryCategory: 'checkout',
    subCategory1: 'gift cards>check balance and reload',
    subCategory2: 'gift cards>check balance and reload>reload successful'
  });

  setTransaction(eventObject, {
    transactionID: orderId || '',
    item: getItems({ orderConfirmation, isGiftCardReload: true }),
    uniqueSkuCount: orderConfirmation?.items?.length || '',
    quantityTotal: orderConfirmation?.itemCount,
  });

  setTransactionPrice(eventObject, {
    ...getTransactionPrice({ orderConfirmation, isGiftCardReload: true })
  });

  setAuroraData(eventObject);

  setProfile(eventObject, {
    additionalEmail: getAdditionalEmail(orderConfirmation),
    address: getProfileAddress(orderConfirmation)
  });

  setContent(eventObject, {}, orderConfirmation, customer);

  if (eventObject?.transaction?.price?.basePrice) {
    delete eventObject.transaction.price.basePrice;
  }

  publishPageLoadAnalytics({ eventObject });
};

const handlePageLoadError = (message, code) => {
  try {
    const eventObject = {
      eventInfo: {
        error: [{
          type: 'error',
          displayType: 'page',
          message,
          code
        }]
      }
    };
    publishEvent({ eventObject });
  } catch (error) {
    console.error(error);
  }
};

const hdppLinkDisplayedEvent = (output) => {
  try {
    const displayedProtectionPlan = !!output?.displayedProtectionPlan;
    const eventObject = {
      category: {
        primaryCategory: 'hdpp content'
      },
      eventInfo: {
        eventName: 'hdpp display'
      },
      eventMode: 'preload',
      componentInfo: {
        displayedProtectionPlan
      }
    };

    // gotta give time for page to setup adobe analytics engine
    setTimeout(() => {
      publishEvent({ eventObject });
    }, 3000);
  } catch (err) {
    console.error('Error in hdppLinkDisplayedEvent()', err);
  }
};

const setEventInfo = (eventObject, variables = {}) => {
  eventObject.eventInfo = {
    ...variables
  };
};

const handleEvent = (primaryCategory, eventName) => {
  try {
    const eventObject = {
      category: {
        primaryCategory
      }
    };
    setEventInfo(eventObject, {
      eventName
    });
    publishEvent({ eventObject });
  } catch (error) {
    console.error(error);
  }
};

export const orderConfirmation = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('order-confirmation-analytics', ({ output = {} }) => {
    try {
      const analyticsEventName = output?.analyticsEventName || '';
      override = output?.override || null;

      switch (analyticsEventName) {
      case EVENTS.HDPP_LINK_DISPLAYED:
        hdppLinkDisplayedEvent(output);
        break;
      case EVENTS.STANDARD_CONFIRMATION:
        standardConfirmation(output);
        break;
      case EVENTS.GIFT_CARD_RELOAD_CONFIRMATION:
        giftCardReloadConfirmation(output);
        break;
      case EVENTS.TOOL_RENTAL_CONFIRMATION:
        toolRentalConfirmation(output);
        break;
      case EVENTS.AMERIGAS_CONFIRMATION:
        amerigasConfirmation(output);
        break;
      case EVENTS.PAGE_LOAD_ERROR:
        handlePageLoadError(output?.message, output?.code);
        break;
      case EVENTS.CREATE_ACCOUNT_AT_CHECKOUT_SUCCESS:
        handleEvent('button submission', 'registration');
        break;
      default:
        console.warn('Unable to find order confirmation analytics event of \'' + analyticsEventName + '\'');
        break;
      }
    } catch (error) {
      console.error('Error in order confirmation analytics', error);
    }
  });
};
