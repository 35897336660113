import serialize from 'serialize-javascript';

const storeHoursMock = {
  monday: { open: '6:00', close: '21:00' },
  tuesday: { open: '6:00', close: '21:00' },
  wednesday: { open: '6:00', close: '21:00' },
  thursday: { open: '6:00', close: '21:00' },
  friday: { open: '6:00', close: '21:00' },
  saturday: { open: '6:00', close: '21:00' },
  sunday: { open: '8:00', close: '20:00' }
};

export const ContextBuilder = {
  server({ expConfiguration = {}, requestData = {}, hosts = {} }) {
    const experienceContext = {
      channel: requestData.channel || 'desktop',
      cookie: {
        adobeCookie: requestData.adobeCookie
      },
      configService: {
        ...expConfiguration,
        searchStream: expConfiguration?.['search.stream'],
        searchStreamLimit: expConfiguration?.['search.streamLimit'],
      },
      hosts,
      path: requestData.route?.path || '/',
      store: {
        storeHours: requestData.storeHours || storeHoursMock,
        storeId: requestData.storeId || '121',
        storeName: requestData.storeName || 'Cumberland',
        storeZip: requestData.localStoreZip || '30339'
      },
      segment: requestData.segment,
      dataType: requestData.dataType,
      isDebugMode: requestData.isDebugMode,
      isLocal: requestData.isLocal || false,
      customer: requestData.customer,
      isConsumerApp: requestData.isConsumerApp,
      localStoreZip: requestData.localStoreZip,
      deliveryZip: requestData.deliveryZip,
      referer: requestData.referer,
      experiment: requestData.experiment || {},
    };
    return {
      contextScript: `
      <script>window.__EXPERIENCE_CONTEXT__ =
      {
        "channel": ${serialize(experienceContext.channel)},
        "cookie": ${serialize(experienceContext.cookie)},
        "customer": ${serialize(experienceContext.customer)},
        "dataType": ${serialize(experienceContext.dataType)},
        "deliveryZip": ${serialize(experienceContext.deliveryZip)},
        "experiment": ${serialize(experienceContext.experiment)},
        "hosts": ${serialize({ ...experienceContext.hosts, api: '', www: '' })},
        "isConsumerApp": ${serialize(experienceContext.isConsumerApp)},
        "isDebugMode": ${serialize(experienceContext.isDebugMode)},
        "isLocal": ${serialize(experienceContext.isLocal)},
        "localStoreZip": ${serialize(experienceContext.localStoreZip)},
        "nearByStores": ${serialize(experienceContext.nearByStores)},
        "path": ${serialize(experienceContext.path)},
        "referer": ${serialize(experienceContext.referer)},
        "segment": ${serialize(experienceContext.segment)},
        "store": ${serialize(experienceContext.store)}
      }
      </script>
      `,
      experienceContext
    };
  },
  client({ expConfiguration = {} } = { expConfiguration: {} }) {
    const ctx = window.__EXPERIENCE_CONTEXT__ || {};
    const experienceContext = {
      channel: ctx.channel,
      configService: {
        ...expConfiguration,
        searchStream: expConfiguration?.['search.stream'],
        searchStreamLimit: expConfiguration?.['search.streamLimit'],
      },
      cookie: ctx.cookie || {},
      customer: ctx.customer || {},
      dataType: ctx.dataType || '',
      deliveryZip: ctx.deliveryZip || '',
      experiment: ctx.experiment || {},
      hosts: ctx.hosts || {},
      isConsumerApp: ctx.isConsumerApp,
      isDebugMode: ctx.isDebugMode || false,
      isLocal: ctx.isLocal,
      localStoreZip: ctx.localStoreZip || '',
      nearByStores: ctx.nearByStores || [],
      path: ctx.path,
      referer: ctx.referer || '',
      segment: ctx.segment || '',
      store: ctx.store || {}
    };
    return {
      experienceContext
    };
  },
};
