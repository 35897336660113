import digitalData from 'digital-data';
import {
  BUTTON_SUBMISSION, MY_ACCOUNT_SETTINGS, OVERLAY
} from '../constants';

const pushEvent = (event) => {
  digitalData.event = digitalData.event || [];
  digitalData.event.push(event);
};

const reportQuoteViewerLoaded = () => {
  const DDOEvent = {
    category: {
      primaryCategory: 'quote details',
      subCategory1: 'services quote>quote details',
      subCategory2: 'services quote>quote details',
    },
    pageInfo: {
      pageName: 'services quote>quote details',
      pageType: 'services quote',
    }
  };
  pushEvent(DDOEvent);
};

const reportPrintViewLoaded = () => {
  const DDOEvent = {
    category: {
      primaryCategory: BUTTON_SUBMISSION,
    },
    eventInfo: {
      eventName: MY_ACCOUNT_SETTINGS,
    },
    myAccount: {
      settings: 'services quote: print details',
    },
  };
  pushEvent(DDOEvent);
};

const reportModifyQuoteLoaded = () => {
  const DDOEvent = {
    category: {
      primaryCategory: 'services quote',
      subCategory1: 'services quote>view quote>modify quote',
      subCategory2: 'services quote>view quote>modify quote',
    },
    pageInfo: {
      pageName: 'services quote>view quote>modify quote',
      pageType: 'services quote',
    }
  };
  pushEvent(DDOEvent);
};

const reportDiscountDetailsLoaded = () => {
  const DDOEvent = {
    category: {
      primaryCategory: 'services quote',
      subCategory1: 'services quote>view quote>discount details',
      subCategory2: 'services quote>view quote>discount details',
    },
    pageInfo: {
      pageName: 'services quote>view quote>discount details',
      pageType: 'services quote',
    }
  };
  pushEvent(DDOEvent);
};

const reportProjectRequirementsOverlay = () => {
  const DDOEvent = {
    category: {
      primaryCategory: OVERLAY,
    },
    eventInfo: {
      eventName: 'services quote details: project requirements',
    }
  };
  pushEvent(DDOEvent);
};

export const quoteViewer = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('quote-viewer.quote-viewer-loaded', reportQuoteViewerLoaded);
  window.LIFE_CYCLE_EVENT_BUS.on('quote-viewer.print-view-loaded', reportPrintViewLoaded);
  window.LIFE_CYCLE_EVENT_BUS.on('quote-viewer.modify-quote-loaded', reportModifyQuoteLoaded);
  window.LIFE_CYCLE_EVENT_BUS.on('quote-viewer.discount-details-loaded', reportDiscountDetailsLoaded);
  window.LIFE_CYCLE_EVENT_BUS.on('quote-viewer.project-requirements-overlay', reportProjectRequirementsOverlay);
};
