import digitalData from 'digital-data';
import {
  STASH, INTERACTION, PIP_SUB_CONTENT, OVERLAY, SHOPPABLE_IMAGES_DRAWER
} from '../constants';
import { getState } from '../analytics-state';

export const productHotspots = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('product-hotspots.product-link-click', ({ output = {} }) => {
    const { sku } = output;
    const { dataSources } = getState() || {};
    const { collectionId, curations } = dataSources?.collection || {};
    const curation = (curations && curations.length) ? curations[0] : {};
    const { curationId } = curation || {};

    const ddoEvent = {
      category: {
        primaryCategory: STASH
      },
      eventMode: STASH,
      eventInfo: {
        eventName: 'collections product click'
      },
      stash: {
        collection: {
          collectionID: collectionId || 'n/a',
          pageLocation: 'hotspots'
        },
        product: [
          {
            productInfo: {
              sku: String(sku)
            },
            curationID: curationId || 'n/a'
          }
        ]
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-hotspots.click', ({ output = {} }) => {
    const { target } = output;
    const ddoEvent = {
      category: {
        primaryCategory: INTERACTION
      },
      eventInfo: {
        eventName: PIP_SUB_CONTENT
      },
      subContent: {
        section: 'expanded-view',
        component: 'hotspots',
        target: target ? target : 'n/a',
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-hotspots.drawer', ({ output = {} }) => {
    const { itemId } = output;
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: SHOPPABLE_IMAGES_DRAWER
      },
      product: [
        {
          productInfo: {
            sku: String(itemId)
          }
        }
      ]
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-hotspots.collapsed-hotspot-pip-click', ({ output = {} }) => {
    const { skus } = output;
    const products = skus.map((sku) => (
      {
        productInfo: {
          sku: String(sku)
        }
      }
    ));
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: 'shoppable images drawer: collapsed hotspot'
      },
      product: products
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-hotspots.collapsed-hotspot-drawer-click', ({ output = {} }) => {
    const { skus } = output;
    const products = skus.map((sku) => (
      {
        productInfo: {
          sku: String(sku)
        }
      }
    ));
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: 'shoppable images drawer: collapsed hotspot - drawer click'
      },
      product: products
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};