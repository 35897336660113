import digitalData from 'digital-data';
import {
  makeEverythingLowerCase,
  setAnalytics
} from '../../../thd-analytics-utils';
import { STORE_FINDER, NUCLEUS } from '../../../constants';

const getExpectedDetailsPageData = (
  state,
  name,
  storeNumber
) => {
  return {
    pageInfo: {
      abTest: '',
      pageName: STORE_FINDER + '>store directory>' + state + '>' + name + '>' + storeNumber,
      pageType: 'tool:store finder:store detail',
      platform: NUCLEUS
    },
    category: {
      primaryCategory: STORE_FINDER,
      subCategory1: STORE_FINDER + '>store directory',
      subCategory2: STORE_FINDER + '>store directory>' + state + '>' + name + '>' + storeNumber
    }
  };
};

export function storeFinder() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('store-finder.store-locator-page-load', () => {
    digitalData.page = {
      pageInfo: {
        abTest: '',
        pageName: STORE_FINDER,
        pageType: STORE_FINDER,
        platform: NUCLEUS
      },
      category: {
        primaryCategory: STORE_FINDER,
        subCategory1: STORE_FINDER,
        subCategory2: STORE_FINDER
      }
    };
    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('store-finder.details-page-load', ({ output: { state, name, storeNumber } }) => {
    digitalData.page = getExpectedDetailsPageData(
      state,
      name,
      storeNumber
    );
    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('store-finder.national-directory-page-load', () => {
    digitalData.page = {
      pageInfo: {
        abTest: '',
        pageName: STORE_FINDER + '>store directory',
        pageType: 'tool:store finder:store directory',
        platform: NUCLEUS
      },
      category: {
        primaryCategory: STORE_FINDER,
        subCategory1: STORE_FINDER + '>store directory',
        subCategory2: STORE_FINDER + '>store directory'
      }
    };
    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('store-finder.state-directory-page-load', ({ output: { state } }) => {
    digitalData.page = {
      pageInfo: {
        abTest: '',
        pageName: STORE_FINDER + '>store directory>' + state,
        pageType: 'tool:store finder:store directory',
        platform: NUCLEUS
      },
      category: {
        primaryCategory: STORE_FINDER,
        subCategory1: STORE_FINDER + '>store directory',
        subCategory2: STORE_FINDER + '>store directory'
      }
    };
    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('store-finder.rental-page-load', ({ output: { state, name, storeNumber } }) => {
    digitalData.page = {
      pageInfo: {
        abTest: '',
        pageName: `${STORE_FINDER}>store directory>${state}>${name}>${storeNumber}>rentals`,
        pageType: 'tool:store finder:rentals',
        platform: NUCLEUS
      },
      category: {
        primaryCategory: STORE_FINDER,
        subCategory1: STORE_FINDER + '>store directory',
        subCategory2: STORE_FINDER + '>store directory>' + state + '>' + name + '>' + storeNumber
      }
    };
    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'store-finder.home-services-page-load', ({ output: { state, name, storeNumber } }) => {
      digitalData.page = {
        pageInfo: {
          abTest: '',
          pageName: `${STORE_FINDER}>store directory>${state}>${name}>${storeNumber}>home services`,
          pageType: 'tool:store finder:home services',
          platform: NUCLEUS
        },
        category: {
          primaryCategory: STORE_FINDER,
          subCategory1: STORE_FINDER + '>store directory',
          subCategory2: STORE_FINDER + '>store directory>' + state + '>' + name + '>' + storeNumber
        }
      };
      digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
      digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
      setAnalytics(digitalData);
    }
  );

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'store-finder.garden-center-page-load', ({ output: { state, name, storeNumber } }) => {
      digitalData.page = {
        pageInfo: {
          abTest: '',
          pageName: `${STORE_FINDER}>store directory>${state}>${name}>${storeNumber}>garden center`,
          pageType: 'tool:store finder:gardencenter',
          platform: NUCLEUS
        },
        category: {
          primaryCategory: STORE_FINDER,
          subCategory1: STORE_FINDER + '>store directory',
          subCategory2: STORE_FINDER + '>store directory>' + state + '>' + name + '>' + storeNumber + '>garden center'
        }
      };
      digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
      digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
      setAnalytics(digitalData);
    }
  );
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'store-finder.pro-desk-page-load', ({ output: { state, name, storeNumber } }) => {
      digitalData.page = {
        pageInfo: {
          abTest: '',
          pageName: `${STORE_FINDER}>store directory>${state}>${name}>${storeNumber}>pro desk`,
          pageType: 'tool:store finder:prodesk',
          platform: NUCLEUS
        },
        category: {
          primaryCategory: STORE_FINDER,
          subCategory1: STORE_FINDER + '>store directory',
          subCategory2: STORE_FINDER + '>store directory>' + state + '>' + name + '>' + storeNumber + '>pro desk'
        }
      };
      digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
      digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
      setAnalytics(digitalData);
    }
  );
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'store-finder.local-city-page-load', ({ output: { city, state } }) => {
      digitalData.page = {
        pageInfo: {
          abTest: '',
          pageName: `${STORE_FINDER}>store directory>${state}>${city}`,
          pageType: 'tool:store finder:lcp',
          platform: NUCLEUS
        },
        category: {
          primaryCategory: STORE_FINDER,
          subCategory1: `${STORE_FINDER}>store directory`,
          subCategory2: `${STORE_FINDER}>store directory>${state}>${city}`
        }
      };

      digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
      digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);

      setAnalytics(digitalData);
    }
  );
}