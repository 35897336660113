import React from 'react';

const defaultContextValue = {
  channel: 'desktop',
  hosts: {},
  path: '/',
  store: {},
  cookie: {},
  configService: {},
  segment: '',
  dataType: '',
  isDebugMode: false,
  isLocal: false,
  customer: {},
  mounted: false,
  isSPAEnabled: false,
  nearByStores: [],
  deliveryZip: '',
  referer: '',
  experiment: {},
};

const DataProviderContext = React.createContext(defaultContextValue);

export default DataProviderContext;
