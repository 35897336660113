import digitalData from 'digital-data';
import {
  getCategoryBreadCrumbs,
  makeEverythingLowerCase,
  setAnalytics
} from '../../../thd-analytics-utils';

export function paginatedProductReviews() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('paginated-product-reviews.page-load', ({ output = {} }) => {
    const product = output?.product || '';
    const pageType = output?.pageType || '';
    const { breadCrumbs = [] } = product?.taxonomy || {};
    const sku = product?.identifiers?.omsThdSku || '';
    digitalData.page = {
      pageInfo: {
        abTest: '',
        pageName: `${pageType}>${sku}`,
        pageType: `tool:${pageType}:pip`,
        pageLoadEvent: '',
      },
      category: getCategoryBreadCrumbs(breadCrumbs),
    };
    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
    setAnalytics(digitalData);
  });
}