import { PAGINATION } from '../constants';
import { getState, setState } from '../analytics-state';

export const pagination = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('pagination.click', function () {
    setState({
      ...getState(),
      pageTransitionEvent: PAGINATION
    });
  });
  window.LIFE_CYCLE_EVENT_BUS.on('product-results.pagination.click', function () {
    setState({
      ...getState(),
      pageTransitionEvent: PAGINATION
    });
  });
};