import digitalData from 'digital-data';

export const cartItem = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('cartItem.DD_EVENT', ({ output = {} }) => {
    const eventObj = output;

    if (eventObj) {
      let eventObject = {
        category: {
          primaryCategory: eventObj?.primaryCategory
        },
        eventInfo: {
          eventName: eventObj?.eventName
        }
      };
      if (eventObj?.eventMode) {
        eventObject.eventMode = eventObj.eventMode;
      }
      if (eventObj?.myAccount?.settings) {
        eventObject.myAccount = {};
        eventObject.myAccount.settings = eventObj.myAccount.settings;
      }
      if (eventObj?.displayedProtectionPlan) {
        eventObject.componentInfo = {};
        eventObject.componentInfo.displayedProtectionPlan = eventObj.displayedProtectionPlan;
      }

      if (eventObj?.eventName) {
        if (eventObj?.eventName === 'cart add') {
          if (eventObj?.isProjectShopping) {
            eventObject.eventInfo.abTest = 'mycart drawer ps';
          } else {
            eventObject.eventInfo.abTest = eventObj?.isDrawer ? 'mycart drawer v1' : 'mycart v1';
          }
        }
        if ((eventObj?.eventName === 'cart add') || (eventObj?.eventName === 'cart remove')) {
          if(eventObj?.packageID) {
            eventObject.eventInfo.packageID = eventObj.packageID;
          }
        }
        if (eventObj?.eventName === 'hdpp') {
          eventObject.eventInfo.abTest = eventObj?.isDrawer ? 'mycart drawer v1' : 'mycart v1';
        }
      }
      if (eventObj?.item) {
        if (eventObj?.item?.length) {
          eventObject.item = eventObj.item;
        } else {
          eventObject.item = [];
          eventObject.item.push(eventObj.item);
        }
      } else if (eventObj?.stash) {
        eventObject.stash = eventObj.stash;
      } else if (eventObj?.quote) {
        if (eventObj?.quote?.item && eventObj?.quote?.item?.length > 0) {
          eventObject.quote = eventObj.quote;
        }
      }
      if (eventObj?.overlayLocation) {
        eventObject.overlayLocation = eventObj.overlayLocation;
      }
      if (eventObj?.removeLocation) {
        eventObject.removeLocation = eventObj.removeLocation;
      }
      if (eventObj?.attributes) {
        eventObject.attributes = eventObj.attributes;
      }
      if (typeof eventMessage !== 'undefined') {
        eventObject.eventInfo.eventMessage = eventObj.eventInfo.eventMessage;
      }
      if (eventObj.error) {
        eventObject.eventInfo.error = [];
        eventObject.eventInfo.error.push(eventObj.error);
      }
      if (eventObj?.content) {
        eventObject.content = eventObj.content;
      }
      if (eventObj.paymentMethod) {
        eventObject.paymentMethod = eventObj.paymentMethod;
      }
      if (eventObj?.cart) {
        eventObject.cart = eventObj.cart;
      }
      if (eventObj?.cartQuantity) {
        eventObject.cartQuantity = eventObj.cartQuantity;
      }
      if (eventObj?.itemId) {
        eventObject.itemId = eventObj.itemId;
      }
      if (eventObj?.emptyCart) {
        eventObject.emptyCart = eventObj.emptyCart;
      }
      if (eventObj?.subContent !== null && eventObj?.subContent !== undefined) {
        eventObject.subContent = eventObj.subContent;
      }
      if (eventObj?.cartViewType) {
        eventObject.cartViewType = eventObj.cartViewType;
      }
      if (eventObj?.bulkAction !== null && eventObj?.bulkAction !== undefined) {
        eventObject.bulkAction = eventObj.bulkAction;
      }
      if (typeof output?.validate === 'function') {
        eventObject = output.validate(eventObject) || eventObject;
      }

      digitalData.event.push(eventObject);
    }
  });
};