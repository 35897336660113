import digitalData from 'digital-data';
import {
  OVERLAY,
  SELECT_PROJECT_ADD_TO,
  SELECT_PROJECT_SUFFIX,
  EVENT_NAME_PREFIX_PROJECTS
} from '../constants';
import { getPageType } from '../thd-analytics-utils';

export const addTo = () => {
  // 7.51.2 Create New Project button click
  LIFE_CYCLE_EVENT_BUS.on(SELECT_PROJECT_ADD_TO, () => {
    const page = getPageType();
    const newPage = EVENT_NAME_PREFIX_PROJECTS[page] ?? page;
    const currentEventName = `${newPage}${SELECT_PROJECT_SUFFIX}`;
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: currentEventName
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};
